import React from "react";
import Card from ".";
import { FiSearch } from "react-icons/fi";
import { Spinner } from "@chakra-ui/react";

const CardContainer = (props) => {
  return (
    <div className="mt-3">
      <Card extra={"w-full p-4 h-full !static"}>
        <div className="mb-8 flex items-center justify-between">
          <div className="w-full">
            {props.title !== "" && (
              <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                {props.title}
              </h4>
            )}
            {props.description !== "" && (
              <p className="mt-2 text-base text-gray-600">
                {props.description}
              </p>
            )}
          </div>
          <div className="flex items-center gap-3">
            {props.withSearchBar && (
              <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                <p className="pl-3 pr-2 text-xl">
                  <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                </p>
                <input
                  type="text"
                  placeholder={props.searchPlaceholder}
                  className="block h-full w-full rounded-full bg-lightPrimary py-4 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                  onChange={props.onSearch}
                />
              </div>
            )}
            {props.rightCTA && (
              <button
                className={`max-w-fit whitespace-nowrap rounded-full bg-brand-500 py-3 px-5 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                  props.disabled ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={props.disabled}
                onClick={props.onClick}
              >
                {props.isCTALoading ? (
                  <Spinner size="sm" className="mx-12" />
                ) : (
                  props.CTACopy ?? "Simpan"
                )}
              </button>
            )}
          </div>
        </div>
        {props.children}
      </Card>
    </div>
  );
};

CardContainer.defaultProps = {
  title: "",
  description: "",
  rightCTA: false,
  disabled: false,
};

export default CardContainer;
