import { useDisclosure } from "@chakra-ui/hooks";
import {
  createMilestone,
  updateMilestone,
  deleteMilestone,
  showAllMilestone,
} from "api/MilestoneAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { convertMonth } from "utils";

const columnsData = [
  {
    Header: "MONTH",
    accessor: "month",
  },
  {
    Header: "YEAR",
    accessor: "year",
  },
  {
    Header: "DESCRIPTION ID",
    accessor: "description_id",
  },
  {
    Header: "DESCRIPTION EN",
    accessor: "description_en",
  },
];

const MilestoneData = () => {
  const [loading, setLoading] = useState(true);

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState({});
  const [action, setAction] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const showAllData = () => {
    setLoading(true);
    showAllMilestone()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 404)
          toast.error("Data tidak dapat ditemukan!");
      });
  };

  const showModal = (action, data) => {
    setAction(action);
    action === "updating" && setTempData(data.row.original);
    if (action === "updating")
      reset({
        date: `${data.row.original.year}-${
          data.row.original.month < 10
            ? "0" + data.row.original.month
            : data.row.original.month
        }`,
        description_id: data.row.original.description_id,
        description_en: data.row.original.description_en,
      });
    else reset({ date: "", description_id: "", description_en: "" });
    onOpen();
  };

  const deleteData = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden 
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  deleteMilestone(data.row.original.id)
                    .then((res) => {
                      if (res.status === 200) {
                        showAllData();
                        toast.success("Berhasil dihapus!");
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error(err.response.data.message);
                    });
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSubmit = (data) => {
    setLoading(true);
    const month = data.date.split("-")[1];
    const year = data.date.split("-")[0];

    const body = {
      ...data,
      month,
      year,
    };
    let promise = null;

    if (action === "adding") promise = createMilestone(body);
    else if (action === "updating")
      promise = updateMilestone(tempData.id, body);

    toast.promise(promise, {
      pending: "Loading...",
      success: `Berhasil ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      error: "Error while retrieving data",
    });

    onClose();

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          onClose();
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "timestamp",
        Header: "TIMESTAMP",
        Cell: (row) =>
          convertMonth(row.cell.row.values.month) +
          ", " +
          row.cell.row.values.year,
      },
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={data}
        columnsData={columnsData}
        tableTitle={"Milestone / Tonggak Sejarah Perusahaan"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["month", "year"]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        header={action === "adding" ? "Tambah Milestone" : "Update Milestone"}
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-3">
            <InputField
              extra="mb-3 w-full"
              label="Waktu Milestone*"
              placeholder="Pilih Waktu Milestone"
              id="date"
              type="month"
              name="date"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Waktu Milestone harus diisi!",
              }}
            />
          </div>
          <div className="flex flex-col gap-3">
            <TextField
              extra="mb-3"
              label="Deskripsi (ID)*"
              id="description_id"
              type="text"
              name="description_id"
              errors={errors}
              register={register}
              rows={5}
              validationSchema={{
                required: `Deskripsi (ID) harus diisi!`,
              }}
            />
            <TextField
              extra="mb-3"
              label="Deskripsi (EN)*"
              id="description_en"
              type="text"
              name="description_en"
              errors={errors}
              register={register}
              rows={5}
              validationSchema={{
                required: `Deskripsi (EN) harus diisi!`,
              }}
            />
          </div>
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
              type="submit"
            >
              {action === "adding" ? "Tambah" : "Update"}
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => onClose()}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default MilestoneData;
