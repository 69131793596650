import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ChakraProvider } from "@chakra-ui/react";
import ThemeProvider from "contexts/themeContext/ThemeContext";
import ElementHandlerProvider from "contexts/elementHandlerContext/ElementHandlerContext";
import UserProvider from "contexts/userContext/UserContext";

import App from "./App";
import AppTheme from "theme";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";

Sentry.init({
  dsn: "https://f9b585390dbb4033826ae27178b1cf8b@o4505335257169920.ingest.sentry.io/4505335265427456",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "pon.co.id",
        "admin.pon.co.id",
        "pon.co.id/api",
        // /^https:\/\/admin\.pon\.co\.id/,
        // /^https:\/\/pon\.co\.id\/api/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <UserProvider>
        <ElementHandlerProvider>
          <ChakraProvider theme={AppTheme}>
            <App />
            <ToastContainer />
          </ChakraProvider>
        </ElementHandlerProvider>
      </UserProvider>
    </ThemeProvider>
  </BrowserRouter>
);
