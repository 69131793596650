import {
  MdCalendarToday,
  MdCardMembership,
  MdDescription,
  MdHorizontalRule,
  MdImage,
  MdShortText,
  MdTextFields,
  MdViewCarousel,
} from "react-icons/md";
import { CgDockLeft, CgDockRight } from "react-icons/cg";
import { TbChartAreaFilled, TbLayoutAlignRight } from "react-icons/tb";
import { BsPieChartFill } from "react-icons/bs";

export const articleElTypes = [
  {
    id: "draggable-1",
    type: "image",
    name: "Gambar",
    src: "",
    alt: "",
    uuid: "",
    file: undefined,
    icon: <MdImage />,
  },
  {
    id: "draggable-2",
    type: "carousel",
    name: "Carousel",
    icon: <MdViewCarousel />,
  },
  {
    id: "draggable-3",
    type: "short-about",
    name: "Tentang Kami",
    icon: <MdCardMembership />,
  },
  {
    id: "draggable-4",
    type: "divider",
    name: "Pembatas",
    icon: <MdHorizontalRule />,
  },
  {
    id: "draggable-5",
    type: "title",
    name: "Judul",
    text: "",
    icon: <MdTextFields />,
  },
  {
    id: "draggable-6",
    type: "subtitle",
    name: "Sub Judul",
    text: "",
    icon: <MdShortText />,
  },
  {
    id: "draggable-7",
    type: "description",
    name: "Deskripsi",
    text: "",
    icon: <MdDescription />,
  },
  {
    id: "draggable-8",
    type: "date",
    name: "Tanggal",
    date: "",
    icon: <MdCalendarToday />,
  },
  {
    id: "draggable-9",
    type: "filled-container",
    name: "Texts - Image",
    date: "",
    icon: <CgDockLeft />,
  },
  {
    id: "draggable-10",
    type: "filled-container",
    name: "Image - Texts",
    date: "",
    icon: <CgDockRight />,
  },
  {
    id: "draggable-9",
    type: "filled-container",
    name: "Title - Chart",
    date: "",
    icon: <BsPieChartFill />,
  },
  {
    id: "draggable-10",
    type: "filled-container",
    name: "Chart - Texts",
    date: "",
    icon: <TbLayoutAlignRight />,
  },
  {
    id: "draggable-11",
    type: "map-chart",
    name: "Map - Chart",
    date: "",
    icon: <TbChartAreaFilled />,
  },
];
