import axiosInstance from "./config";

export const createImageList = (body) => {
  return axiosInstance.post("/image-lists", body);
};

export const updateImageList = (id, body) => {
  return axiosInstance.post(`/image-lists/update/${id}`, body);
};

export const deleteImageList = (id) => {
  return axiosInstance.get(`/image-lists/delete/${id}`);
};
