import axiosInstance from "./config";

export const createProductCards = (body) => {
  return axiosInstance.post("/product-cards", body);
};

export const updateProductCards = (id, body) => {
  return axiosInstance.post(`/product-cards/update/${id}`, body);
};

export const showProductCards = (id) => {
  return axiosInstance.get(`/product-cards/${id}`);
};

export const deleteProductCards = (id) => {
  return axiosInstance.get(`/product-cards/delete/${id}`);
};
