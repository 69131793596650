import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import React from "react";
import { Trash } from "react-feather";
import { BiInfoCircle } from "react-icons/bi";

const Template2 = (props) => {
  const {
    register,
    errors,
    existingImages,
    dropFile,
    removeFile,
    file,
    preview,
    thumbnails,
    handleDeleteVideo,
    handleInputVideo,
  } = props;

  return (
    <div className="flex flex-col items-start justify-between gap-3">
      <div className="flex w-full flex-1 flex-col gap-5">
        <div className="flex gap-3">
          <InputField
            extra="mb-3 w-full"
            label={`Subtitle (EN)*`}
            id={`subtitle_en`}
            type="text"
            name={`subtitle_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Subtitle (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3 w-full"
            label={`Subtitle (ID)*`}
            id={`subtitle_id`}
            type="text"
            name={`subtitle_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Subtitle (ID) harus diisi!`,
            }}
          />
        </div>
        <div className="flex flex-col gap-3">
          <InputField
            extra="mb-3"
            label={`Title (EN)*`}
            id={`title_en`}
            type="text"
            name={`title_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3"
            label={`Title (ID)*`}
            id={`title_id`}
            type="text"
            name={`title_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (ID) harus diisi!`,
            }}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="ml-3 flex items-center gap-2 text-navy-400">
          <BiInfoCircle />
          <p>Pilih menggunakan foto atau video</p>
        </div>
        <div className="flex items-start gap-5">
          {preview?.length === 0 && (
            <div className="flex flex-1 flex-col">
              {existingImages && (
                <div className="relative m-3 my-3 flex w-full max-w-xs items-center justify-center rounded-xl border-2 border-gray-200">
                  <img
                    src={
                      existingImages === undefined
                        ? "https://via.placeholder.com/120x100?text=No+Image"
                        : process.env.REACT_APP_API_ENDPOINT +
                          "/images/" +
                          existingImages
                    }
                    alt={
                      existingImages !== undefined &&
                      process.env.REACT_APP_API_ENDPOINT +
                        "/images/" +
                        existingImages
                    }
                    className="rounded-lg"
                  />
                </div>
              )}
              <div className="flex w-full items-center gap-3">
                <div className="flex flex-1 gap-5">
                  <DropzoneFile
                    text="Upload file"
                    accept={{
                      "image/jpeg": [".jpg", ".jpeg", ".png"],
                      "image/svg+xml": [".svg"],
                    }}
                    dropFile={dropFile}
                    removeFile={removeFile}
                    file={file[0]}
                  />
                </div>
              </div>
            </div>
          )}
          {!file[0] && (
            <div className="flex w-full flex-1 flex-col gap-3">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-1 flex-col gap-3">
                  <div className="w-full flex-1">
                    <InputField
                      extra="mb-3"
                      label="Link Youtube"
                      placeholder="https://www.youtube.com/watch?v=..."
                      id="youtube"
                      type="text"
                      name="youtube"
                      errors={errors}
                      register={register}
                      disabled={preview?.length >= 1}
                      validationSchema={{
                        onChange: (e) => {
                          handleInputVideo(e);
                        },
                      }}
                    />
                  </div>
                  {preview?.length > 0 ? (
                    preview?.map((preview, index) => {
                      return (
                        <div key={index}>
                          <div className="mb-4 flex flex-col gap-3">
                            <div className="mb-3 mr-3">
                              <img
                                src={
                                  thumbnails?.length === 0
                                    ? `https://via.placeholder.com/640x360.png?text=No+Thumbnail`
                                    : thumbnails[index]
                                }
                                alt={thumbnails[index]}
                                className="h-full w-full rounded-lg object-cover"
                              />
                            </div>
                            <div>
                              <div className="flex flex-col justify-between">
                                <div>
                                  <h3 className="mb-1 w-full text-lg font-bold">
                                    {preview?.title}
                                  </h3>
                                  <p
                                    className="w-full overflow-hidden text-gray-700"
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {preview?.subtitle}
                                  </p>
                                </div>
                                <div className="flex w-full items-end justify-between">
                                  <div>
                                    <p className="mb-0 text-sm font-semibold text-gray-700">
                                      Durasi
                                    </p>
                                    <p className="font-semibold">
                                      {preview?.runtime}
                                    </p>
                                  </div>
                                  <div
                                    className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-red-100 py-2 px-3 text-sm font-medium text-red-600 transition duration-200 hover:text-red-700 dark:bg-red-500 dark:text-red-400 dark:hover:text-red-300 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                                  >
                                    <button
                                      className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                                      onClick={() => handleDeleteVideo(index)}
                                    />
                                    <Trash width={16} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="my-3 text-center font-semibold text-gray-600">
                      Belum ada video yang ditambahkan. Silahkan tambahkan video
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Template2;
