import { useDisclosure } from "@chakra-ui/hooks";
import { uploadImages } from "api/ImageAPI";
import {
  createLogo,
  deleteLogo,
  getAllLogo,
  showLogo,
  updateLogo,
} from "api/LogoAPI";
import CardContainer from "components/card/CardContainer";
import DropzoneFile from "components/dropzone/DropzoneFile";
import BasicModal from "components/modal/BasicModal";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import Widget from "components/widget/Widget";
import React from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { MdPhotoLibrary } from "react-icons/md";
import { toast } from "react-toastify";

const Logo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = React.useState({
    loading: true,
    data: null,
  });
  const [progress, setProgress] = React.useState(0);
  const [confirm, setConfirm] = React.useState({
    show: false,
    data: null,
  });
  const [isUploading, setIsUploading] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [existingImg, setExistingImg] = React.useState(null);
  const [payload, setPayload] = React.useState({
    body: {
      file: undefined,
    },
  });

  const { handleSubmit } = useForm();

  // File upload
  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        file: file,
      },
    }));
  };

  const removeFile = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        file: undefined,
      },
    });
  };
  // End file upload

  const showUpdateModal = (action, id) => {
    setAction(action);
    if (action === "update") {
      showLogo(id)
        .then((res) => {
          setExistingImg(res.data.data.data);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Gagal memuat logo!");
        });
    }
    onOpen();
  };

  const handleDeleteLogo = (id) => {
    deleteLogo(id)
      .then((res) => {
        if (res.status === 200) {
          getContent();
          toast.success("Berhasil menghapus logo!");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal menghapus logo!");
      });
  };

  const onSubmit = async () => {
    let promise = null;
    let img = null;
    let imageBody = null;

    imageBody = [payload.body.file].filter(Boolean);

    const finalImageBody = {
      images: imageBody,
    };

    if (imageBody) {
      setIsUploading(true);
      try {
        img = await uploadImages(finalImageBody, setProgress);
      } catch (err) {
        setIsUploading(false);
        console.error(err);
        toast.error("Gagal mengunggah foto, silahkan coba lagi!");
      }
    }

    action === "create" &&
      (promise = createLogo({ img: img?.data?.data?.data[0] }));
    action === "update" &&
      (promise = updateLogo(existingImg.id, { img: img?.data?.data?.data[0] }));

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getContent();
          onClose();
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setPayload({
      body: {
        file: undefined,
      },
    });
    setIsUploading(false);
    onClose();
  };

  const getContent = () => {
    getAllLogo()
      .then((res) => {
        setContent({
          loading: false,
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal memuat data Footer! Lakukan refresh halaman");
      });
  };

  React.useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <CardContainer
        title="Website Setting"
        description="Kelola pengaturan website Anda disini"
        CTACopy="Tambah Logo"
        rightCTA={true}
        onClick={() => showUpdateModal("create")}
      >
        <div className="flex flex-col gap-3">
          {content?.data?.data && content?.data?.data?.length > 0 ? (
            content?.data?.data?.map((item, index) => {
              return (
                <div key={index}>
                  <Widget
                    title={`Logo ${index + 1}`}
                    subtitle={"1 Gambar"}
                    icon={<MdPhotoLibrary />}
                    updateUrl={() => showUpdateModal("update", item?.id)}
                    removeData={() => setConfirm({ show: true, data: item })}
                  />
                </div>
              );
            })
          ) : (
            <div className="flex flex-col items-center justify-center gap-1">
              <p className="text-lg font-semibold text-gray-700">
                Belum ada logo
              </p>
              <p className="text-sm font-medium text-gray-600">
                Silahkan tambahkan logo baru
              </p>
            </div>
          )}
        </div>
      </CardContainer>
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({ show: false, data: null });
            }}
            onConfirm={() => {
              handleDeleteLogo(confirm.data.id);
              setConfirm({ show: false, data: null });
            }}
          />,
          document.body
        )}
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setExistingImg(null);
        }}
        header="Update Data"
        scrollBehavior="inside"
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                  transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5">
              {existingImg && (
                <div className="flex w-full items-center gap-3">
                  <div className="flex h-40 w-full justify-center overflow-hidden rounded-xl border-2 border-gray-200 p-1 text-center">
                    <img
                      src={
                        process.env.REACT_APP_API_ENDPOINT +
                        "/images/" +
                        existingImg?.img
                      }
                      className="h-full"
                      alt="Logo Footer"
                    />
                  </div>
                </div>
              )}
              <div className="flex w-full items-center gap-3">
                <DropzoneFile
                  text="Upload file"
                  accept={{
                    "image/jpeg": [".jpg", ".jpeg", ".png"],
                  }}
                  dropFile={dropFile}
                  removeFile={removeFile}
                  file={payload.body.file}
                />
              </div>
            </div>
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                {action === "create" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  onClose();
                  setExistingImg(null);
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default Logo;
