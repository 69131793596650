export const countries = [
  {
    id: "AF",
    name: "Afghanistan",
    type: "country",
  },
  {
    id: "AX",
    name: "Åland Islands",
    type: "country",
  },
  {
    id: "AL",
    name: "Albania",
    type: "country",
  },
  {
    id: "AS",
    name: "American Samoa",
    type: "country",
  },
  {
    id: "AD",
    name: "Andorra",
    type: "country",
  },
  {
    id: "AO",
    name: "Angola",
    type: "country",
  },
  {
    id: "AI",
    name: "Anguilla",
    type: "country",
  },
  {
    id: "AQ",
    name: "Antarctica",
    type: "country",
  },
  {
    id: "AG",
    name: "Antigua and Barbuda",
    type: "country",
  },
  {
    id: "AR",
    name: "Argentina",
    type: "country",
  },
  {
    id: "AM",
    name: "Armenia",
    type: "country",
  },
  {
    id: "AW",
    name: "Aruba",
    type: "country",
  },
  {
    id: "AU",
    name: "Australia",
    type: "country",
  },
  {
    id: "AT",
    name: "Austria",
    type: "country",
  },
  {
    id: "AZ",
    name: "Azerbaijan",
    type: "country",
  },
  {
    id: "BS",
    name: "Bahamas",
    type: "country",
  },
  {
    id: "BH",
    name: "Bahrain",
    type: "country",
  },
  {
    id: "BD",
    name: "Bangladesh",
    type: "country",
  },
  {
    id: "BB",
    name: "Barbados",
    type: "country",
  },
  {
    id: "BY",
    name: "Belarus",
    type: "country",
  },
  {
    id: "BE",
    name: "Belgium",
    type: "country",
  },
  {
    id: "BZ",
    name: "Belize",
    type: "country",
  },
  {
    id: "BJ",
    name: "Benin",
    type: "country",
  },
  {
    id: "BM",
    name: "Bermuda",
    type: "country",
  },
  {
    id: "BT",
    name: "Bhutan",
    type: "country",
  },
  {
    id: "BO",
    name: "Bolivia (Plurinational State of)",
    type: "country",
  },
  {
    id: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    type: "country",
  },
  {
    id: "BA",
    name: "Bosnia and Herzegovina",
    type: "country",
  },
  {
    id: "BW",
    name: "Botswana",
    type: "country",
  },
  {
    id: "BV",
    name: "Bouvet Island",
    type: "country",
  },
  {
    id: "BR",
    name: "Brazil",
    type: "country",
  },
  {
    id: "IO",
    name: "British Indian Ocean Territory",
    type: "country",
  },
  {
    id: "BN",
    name: "Brunei Darussalam",
    type: "country",
  },
  {
    id: "BG",
    name: "Bulgaria",
    type: "country",
  },
  {
    id: "BF",
    name: "Burkina Faso",
    type: "country",
  },
  {
    id: "BI",
    name: "Burundi",
    type: "country",
  },
  {
    id: "KH",
    name: "Cambodia",
    type: "country",
  },
  {
    id: "CA",
    name: "Canada",
    type: "country",
  },
  {
    id: "CF",
    name: "Central African Republic",
    type: "country",
  },
  {
    id: "TD",
    name: "Chad",
    type: "country",
  },
  {
    id: "CX",
    name: "Christmas Island",
    type: "country",
  },
  {
    id: "CC",
    name: "Cocos (Keeling) Islands",
    type: "country",
  },
  {
    id: "CO",
    name: "Colombia",
    type: "country",
  },
  {
    id: "KM",
    name: "Comoros",
    type: "country",
  },
  {
    id: "CV",
    name: "Cape Verde",
    type: "country",
  },
  {
    id: "CW",
    name: "Curaçao",
    type: "country",
  },
  {
    id: "CZ",
    name: "Czechia",
    type: "country",
  },
  {
    id: "CK",
    name: "Cook Islands",
    type: "country",
  },
  {
    id: "CL",
    name: "Chile",
    type: "country",
  },
  {
    id: "CN",
    name: "China",
    type: "country",
  },
  {
    id: "CI",
    name: "Côte d'Ivoire",
    type: "country",
  },
  {
    id: "CM",
    name: "Cameroon",
    type: "country",
  },
  {
    id: "CD",
    name: "Democratic Republic of the Congo",
    type: "country",
  },
  {
    id: "CG",
    name: "Republic of the Congo",
    type: "country",
  },
  {
    id: "CR",
    name: "Costa Rica",
    type: "country",
  },
  {
    id: "CU",
    name: "Cuba",
    type: "country",
  },
  {
    id: "CY",
    name: "Cyprus",
    type: "country",
  },
  {
    id: "DE",
    name: "Germany",
    type: "country",
  },
  {
    id: "DJ",
    name: "Djibouti",
    type: "country",
  },
  {
    id: "DK",
    name: "Denmark",
    type: "country",
  },
  {
    id: "DM",
    name: "Dominica",
    type: "country",
  },
  {
    id: "DO",
    name: "Dominican Republic",
    type: "country",
  },
  {
    id: "DZ",
    name: "Algeria",
    type: "country",
  },
  {
    id: "EC",
    name: "Ecuador",
    type: "country",
  },
  {
    id: "EE",
    name: "Estonia",
    type: "country",
  },
  {
    id: "EG",
    name: "Egypt",
    type: "country",
  },
  {
    id: "EH",
    name: "Western Sahara",
    type: "country",
  },
  {
    id: "ER",
    name: "Eritrea",
    type: "country",
  },
  {
    id: "ET",
    name: "Ethiopia",
    type: "country",
  },
  {
    id: "FI",
    name: "Finland",
    type: "country",
  },
  {
    id: "FJ",
    name: "Fiji",
    type: "country",
  },
  {
    id: "FK",
    name: "Falkland Islands",
    type: "country",
  },
  {
    id: "FM",
    name: "Micronesia",
    type: "country",
  },
  {
    id: "FO",
    name: "Faroe Islands",
    type: "country",
  },
  {
    id: "FR",
    name: "France",
    type: "country",
  },
  {
    id: "GA",
    name: "Gabon",
    type: "country",
  },
  {
    id: "GB",
    name: "United Kingdom",
    type: "country",
  },
  {
    id: "GD",
    name: "Grenada",
    type: "country",
  },
  {
    id: "GE",
    name: "Georgia",
    type: "country",
  },
  {
    id: "GF",
    name: "French Guiana",
    type: "country",
  },
  {
    id: "GG",
    name: "Guernsey",
    type: "country",
  },
  {
    id: "GH",
    name: "Ghana",
    type: "country",
  },
  {
    id: "GI",
    name: "Gibraltar",
    type: "country",
  },
  {
    id: "GL",
    name: "Greenland",
    type: "country",
  },
  {
    id: "GM",
    name: "Gambia",
    type: "country",
  },
  {
    id: "GN",
    name: "Guinea",
    type: "country",
  },
  {
    id: "GP",
    name: "Guadeloupe",
    type: "country",
  },
  {
    id: "GQ",
    name: "Equatorial Guinea",
    type: "country",
  },
  {
    id: "GR",
    name: "Greece",
    type: "country",
  },
  {
    id: "GS",
    name: "South Georgia and the South Sandwich Islands",
    type: "country",
  },
  {
    id: "GT",
    name: "Guatemala",
    type: "country",
  },
  {
    id: "GU",
    name: "Guam",
    type: "country",
  },
  {
    id: "GW",
    name: "Guinea-Bissau",
    type: "country",
  },
  {
    id: "GY",
    name: "Guyana",
    type: "country",
  },
  {
    id: "HK",
    name: "Hong Kong SAR China",
    type: "country",
  },
  {
    id: "HM",
    name: "Heard Island and McDonald Islands",
    type: "country",
  },
  {
    id: "HN",
    name: "Honduras",
    type: "country",
  },
  {
    id: "HR",
    name: "Croatia",
    type: "country",
  },
  {
    id: "HT",
    name: "Haiti",
    type: "country",
  },
  {
    id: "HU",
    name: "Hungary",
    type: "country",
  },
  {
    id: "ID",
    name: "Indonesia",
    type: "country",
  },
  {
    id: "IE",
    name: "Ireland",
    type: "country",
  },
  {
    id: "IL",
    name: "Israel",
    type: "country",
  },
  {
    id: "IM",
    name: "Isle of Man",
    type: "country",
  },
  {
    id: "IN",
    name: "India",
    type: "country",
  },
  {
    id: "IQ",
    name: "Iraq",
    type: "country",
  },
  {
    id: "IR",
    name: "Iran",
    type: "country",
  },
  {
    id: "IS",
    name: "Iceland",
    type: "country",
  },
  {
    id: "IT",
    name: "Italy",
    type: "country",
  },
  {
    id: "JE",
    name: "Jersey",
    type: "country",
  },
  {
    id: "JM",
    name: "Jamaica",
    type: "country",
  },
  {
    id: "JO",
    name: "Jordan",
    type: "country",
  },
  {
    id: "JP",
    name: "Japan",
    type: "country",
  },
  {
    id: "KE",
    name: "Kenya",
    type: "country",
  },
  {
    id: "KG",
    name: "Kyrgyzstan",
    type: "country",
  },
  {
    id: "KI",
    name: "Kiribati",
    type: "country",
  },
  {
    id: "KN",
    name: "Saint Kitts and Nevis",
    type: "country",
  },
  {
    id: "KP",
    name: "North Korea",
    type: "country",
  },
  {
    id: "KR",
    name: "South Korea",
    type: "country",
  },
  {
    id: "KW",
    name: "Kuwait",
    type: "country",
  },
  {
    id: "KY",
    name: "Cayman Islands",
    type: "country",
  },
  {
    id: "KZ",
    name: "Kazakhstan",
    type: "country",
  },
  {
    id: "LA",
    name: "Laos",
    type: "country",
  },
  {
    id: "LB",
    name: "Lebanon",
    type: "country",
  },
  {
    id: "LC",
    name: "Saint Lucia",
    type: "country",
  },
  {
    id: "MF",
    name: "Saint Martin (French part)",
    type: "country",
  },
  {
    id: "WS",
    name: "Samoa",
    type: "country",
  },
  {
    id: "SM",
    name: "San Marino",
    type: "country",
  },
  {
    id: "ST",
    name: "Sao Tome and Principe",
    type: "country",
  },
  {
    id: "SA",
    name: "Saudi Arabia",
    type: "country",
  },
  {
    id: "SN",
    name: "Senegal",
    type: "country",
  },
  {
    id: "RS",
    name: "Serbia",
    type: "country",
  },
  {
    id: "SC",
    name: "Seychelles",
    type: "country",
  },
  {
    id: "SL",
    name: "Sierra Leone",
    type: "country",
  },
  {
    id: "SG",
    name: "Singapore",
    type: "country",
  },
  {
    id: "SX",
    name: "Sint Maarten (Dutch part)",
    type: "country",
  },
  {
    id: "SK",
    name: "Slovakia",
    type: "country",
  },
  {
    id: "SI",
    name: "Slovenia",
    type: "country",
  },
  {
    id: "SB",
    name: "Solomon Islands",
    type: "country",
  },
  {
    id: "SO",
    name: "Somalia",
    type: "country",
  },
  {
    id: "ZA",
    name: "South Africa",
    type: "country",
  },
  {
    id: "SS",
    name: "South Sudan",
    type: "country",
  },
  {
    id: "ES",
    name: "Spain",
    type: "country",
  },
  {
    id: "LK",
    name: "Sri Lanka",
    type: "country",
  },
  {
    id: "SD",
    name: "Sudan",
    type: "country",
  },
  {
    id: "SR",
    name: "Suriname",
    type: "country",
  },
  {
    id: "SJ",
    name: "Svalbard and Jan Mayen",
    type: "country",
  },
  {
    id: "SE",
    name: "Sweden",
    type: "country",
  },
  {
    id: "CH",
    name: "Switzerland",
    type: "country",
  },
  {
    id: "SY",
    name: "Syrian Arab Republic",
    type: "country",
  },
  {
    id: "TW",
    name: "Taiwan, Province of China",
    type: "country",
  },
  {
    id: "TJ",
    name: "Tajikistan",
    type: "country",
  },
  {
    id: "TZ",
    name: "Tanzania, United Republic of",
    type: "country",
  },
  {
    id: "TH",
    name: "Thailand",
    type: "country",
  },
  {
    id: "TL",
    name: "Timor-Leste",
    type: "country",
  },
  {
    id: "TG",
    name: "Togo",
    type: "country",
  },
  {
    id: "TK",
    name: "Tokelau",
    type: "country",
  },
  {
    id: "TO",
    name: "Tonga",
    type: "country",
  },
  {
    id: "TT",
    name: "Trinidad and Tobago",
    type: "country",
  },
  {
    id: "TN",
    name: "Tunisia",
    type: "country",
  },
  {
    id: "TR",
    name: "Turkey",
    type: "country",
  },
  {
    id: "TM",
    name: "Turkmenistan",
    type: "country",
  },
  {
    id: "TC",
    name: "Turks and Caicos Islands",
    type: "country",
  },
  {
    id: "TV",
    name: "Tuvalu",
    type: "country",
  },
  {
    id: "UG",
    name: "Uganda",
    type: "country",
  },
  {
    id: "UA",
    name: "Ukraine",
    type: "country",
  },
  {
    id: "AE",
    name: "United Arab Emirates",
    type: "country",
  },
  {
    id: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland",
    type: "country",
  },
  {
    id: "US",
    name: "United States of America",
    type: "country",
  },
  {
    id: "UM",
    name: "United States Minor Outlying Islands",
    type: "country",
  },
  {
    id: "UY",
    name: "Uruguay",
    type: "country",
  },
  {
    id: "UZ",
    name: "Uzbekistan",
    type: "country",
  },
  {
    id: "VU",
    name: "Vanuatu",
    type: "country",
  },
  {
    id: "VE",
    name: "Venezuela (Bolivarian Republic of)",
    type: "country",
  },
  {
    id: "VN",
    name: "Viet Nam",
    type: "country",
  },
  {
    id: "VG",
    name: "British Virgin Islands",
    type: "country",
  },
  {
    id: "VI",
    name: "US Virgin Islands",
    type: "country",
  },
  {
    id: "WF",
    name: "Wallis and Futuna Islands",
    type: "country",
  },
  {
    id: "YE",
    name: "Yemen",
    type: "country",
  },
  {
    id: "ZM",
    name: "Zambia",
    type: "country",
  },
  {
    id: "ZW",
    name: "Zimbabwe",
    type: "country",
  },
  {
    id: "LNLN",
    name: "Lain-lain",
    type: "country",
  },
];
