import axiosInstance from "./config";

export const createTitle = (body) => {
  return axiosInstance.post("/titles", body);
};

export const updateTitle = (id, body) => {
  return axiosInstance.post(`/titles/update/${id}`, body);
};

export const deleteTitle = (id) => {
  return axiosInstance.get(`/titles/delete/${id}`);
};
