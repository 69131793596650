import React, { useEffect, useState } from "react";

// Libs
import { useDisclosure } from "@chakra-ui/hooks";
import { TableActionButton } from "components/buttons/TableActionButton";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// APIs
import { getPageContent } from "api/PageAPI";
import { getAllPageContent } from "api/PageAPI";
import { updateTextBox } from "api/TextBoxAPI";
import { showProductBySlug } from "api/ProductAPI";

// Components & Views
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import InputField from "components/fields/InputField";
import { createPortal } from "react-dom";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import { useLocation, useParams } from "react-router-dom";

const columnsData = [
  {
    Header: "TITLE (EN)",
    accessor: "title_en",
  },
  {
    Header: "SUBTITLE (EN)",
    accessor: "subtitle_en",
  },
  {
    Header: "TITLE (ID)",
    accessor: "title_id",
  },
  {
    Header: "SUBTITLE (ID)",
    accessor: "subtitle_id",
  },
];

const ProductTextBoxes = () => {
  const location = useLocation();
  const { slug, default_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [productTextBox, setProductTextBox] = useState([]);
  const [targetEl, setTargetEl] = useState([]);

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState(null);
  const [confirm, setConfirm] = useState({
    show: false,
    data: null,
  });
  const [action, setAction] = useState(null);
  const [sectionProps, setSectionProps] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const refactorData = (data) => {
    return data?.title_id?.map((_value, index) => ({
      title_id: data.title_id[index],
      title_en: data.title_en[index],
      subtitle_id: data.subtitle_id[index],
      subtitle_en: data.subtitle_en[index],
    }));
  };

  const getTargetPage = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = await getAllPageContent();
    return allPageData.data.data.find(
      (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
    );
  };

  const showAllData = async () => {
    setLoading(true);
    if (slug) {
      showProductBySlug(slug)
        .then((res) => {
          const targetEl = res.data.data.contents.find((item) => {
            return (
              item.name === "text_boxes" && item.id === parseInt(default_id)
            );
          });
          setSectionProps(targetEl);
          setProductTextBox(targetEl?.body);
          setTargetEl(refactorData(targetEl?.body));
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    } else {
      const targetPage = await getTargetPage();
      getPageContent(targetPage.id)
        .then((res) => {
          const targetEl = res.data.data.contents.find((item) => {
            return (
              item.name === "text_boxes" && item.id === parseInt(default_id)
            );
          });
          setSectionProps(targetEl);
          setProductTextBox(targetEl?.body);
          setTargetEl(refactorData(targetEl?.body));
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    }
  };

  const showModal = (action, data) => {
    reset({
      title_id: data?.row.original.title_id,
      title_en: data?.row.original.title_en,
      subtitle_id: data?.row.original.subtitle_id,
      subtitle_en: data?.row.original.subtitle_en,
    });
    setId(data?.row.index);
    setAction(action);
    onOpen();
  };

  const deleteData = (data) => {
    setAction("deleting");
    setConfirm({
      show: true,
      data: data.row.index,
    });
  };

  const onSubmit = (data) => {
    const oldTextBox = productTextBox;
    const title_id = [...(oldTextBox.title_id || [])];
    const title_en = [...(oldTextBox.title_en || [])];
    const subtitle_id = [...(oldTextBox.subtitle_id || [])];
    const subtitle_en = [...(oldTextBox.subtitle_en || [])];
    let finalBody = {
      order: sectionProps.order,
      name: sectionProps.name,
      body: {
        title_id,
        title_en,
        subtitle_id,
        subtitle_en,
      },
    };

    if (slug) {
      finalBody = {
        ...finalBody,
        product_id: sectionProps.product_id,
        body: {
          ...finalBody.body,
        },
      };
    } else {
      finalBody = {
        ...finalBody,
        page_id: sectionProps.page_id,
        body: {
          ...finalBody.body,
        },
      };
    }

    if (action === "adding") {
      finalBody.body.title_id.push(data.title_id);
      finalBody.body.title_en.push(data.title_en);
      finalBody.body.subtitle_id.push(data.subtitle_id);
      finalBody.body.subtitle_en.push(data.subtitle_en);
    }
    if (action === "updating") {
      finalBody.body.title_id[id] = data.title_id;
      finalBody.body.title_en[id] = data.title_en;
      finalBody.body.subtitle_id[id] = data.subtitle_id;
      finalBody.body.subtitle_en[id] = data.subtitle_en;
    }
    if (action === "deleting") {
      finalBody.body.title_id.splice(confirm.data, 1);
      finalBody.body.title_en.splice(confirm.data, 1);
      finalBody.body.subtitle_id.splice(confirm.data, 1);
      finalBody.body.subtitle_en.splice(confirm.data, 1);
    }

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateTextBox(sectionProps.id, finalBody);

    toast.promise(promise, {
      pending: "Menambahkan data...",
      success: `Data Product Card berhasil di${
        action === "deleting" ? "hapus" : "update"
      }!`,
      error: `Data Product Card gagal di${
        action === "deleting" ? "hapus" : "update"
      }!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });

    setAction(null);
    onClose();
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={targetEl?.length > 0 ? targetEl : []}
        columnsData={columnsData}
        tableTitle={`Text Boxes`}
        CTACopy="Data"
        addData={() => showModal("adding")}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["values"]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({
                show: false,
                data: null,
              });
              setAction(null);
            }}
            onConfirm={() => {
              onSubmit(confirm.data);
              setConfirm({
                show: false,
                data: null,
              });
            }}
          />,
          document.body
        )}
      <BasicModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          reset({});
          onClose();
        }}
        header={action === "adding" ? "Tambah Data" : "Update Data"}
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {
            <div className="flex gap-3">
              <div className="flex flex-1 flex-col gap-3">
                <InputField
                  extra="mb-3 w-full"
                  label={`Title (ID)*`}
                  id={`title_id`}
                  type="text"
                  name={`title_id`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Title (ID) harus diisi!`,
                  }}
                />
                <InputField
                  extra="mb-3 w-full"
                  label={`Subtitle (ID)*`}
                  id={`subtitle_id`}
                  type="text"
                  name={`subtitle_id`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Subtitle (ID) harus diisi!`,
                  }}
                />
              </div>
              <div className="flex flex-1 flex-col gap-3">
                <InputField
                  extra="mb-3 w-full"
                  label={`Title (EN)*`}
                  id={`title_en`}
                  type="text"
                  name={`title_en`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Title (EN) harus diisi!`,
                  }}
                />
                <InputField
                  extra="mb-3 w-full"
                  label={`Subtitle (EN)*`}
                  id={`subtitle_en`}
                  type="text"
                  name={`subtitle_en`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Subtitle (EN) harus diisi!`,
                  }}
                />
              </div>
            </div>
          }
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
              type="submit"
            >
              {action === "adding" ? "Tambah" : "Update"}
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                reset({});
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default ProductTextBoxes;
