import { useDisclosure } from "@chakra-ui/hooks";
import { updateHomeSection } from "api/HomeAPI";
import { getHomeContent } from "api/PageAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import InputField from "components/fields/InputField";
import BasicModal from "components/modal/BasicModal";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import TableCard from "components/table/TableCard";
import React from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getYoutubeID } from "utils";
import { getVideoPreviewData } from "utils/API";

const columnsData = [
  {
    Header: "THUMBNAIL",
    accessor: "thumbnail",
  },
  {
    Header: "URL",
    accessor: "url",
  },
];

const YoutubeData = () => {
  const section = "social_media";
  const navigate = useNavigate();
  const [sectionProps, setSectionProps] = React.useState({
    loading: true,
    data: null,
  });

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [payload, setPayload] = React.useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const showModal = (_action, data) => {
    reset({
      youtube: data.row.original.url,
    });
    setId(data.row.index);
    onOpen();
  };

  const deleteData = (data) => {
    setId(data.row.index);
    setShowConfirm(true);
  };

  const onSubmit = (data) => {
    const videos = sectionProps.data?.body?.youtube || [];
    videos[id] = data.youtube;

    const finalBody = {
      ...sectionProps.data,
    };
    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateHomeSection(sectionProps.data.id, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    onClose();

    promise
      .then((res) => {
        if (res.status === 200) {
          getContent();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // End Modal

  // Youtube Video Handler
  const [videos, setVideos] = React.useState([]);

  const handleVideoData = async () => {
    const newVideo = [];
    const promises = [];
    (sectionProps.data?.body?.youtube || []).map(async (video, index) => {
      const youtubeId = getYoutubeID(video);
      if (youtubeId !== "") {
        promises.push(
          getVideoPreviewData(youtubeId)
            .then((res) => {
              const { runtime, thumbnailHigh } = res;
              const tempRes = {
                runtime: runtime,
                url: sectionProps.data?.body?.youtube[index],
                thumbnail: thumbnailHigh,
              };
              newVideo[index] = tempRes;
            })
            .catch((err) => {
              console.error(err);
              toast.error("Gagal memuat data video!");
            })
        );
      }
    });
    Promise.all(promises).then(() => {
      setVideos(newVideo);
    });
  };
  // End Youtube Video Handler

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionProps({
        loading: false,
        data: res.data.data[index],
      });
    });
  };

  React.useEffect(() => {
    getContent();
  }, []);

  React.useEffect(() => {
    handleVideoData();
  }, [sectionProps.data]);

  return (
    <>
      <TableCard
        tableData={videos || []}
        columnsData={columnsData}
        tableTitle={"Youtube Videos"}
        addData={() => navigate("/home-page/youtube/add")}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        loading={sectionProps.loading}
        isCTALoading={sectionProps.loading}
        disabled={sectionProps.loading}
      />
      {showConfirm &&
        createPortal(
          <ConfirmAlert
            header="Hapus Video"
            description="Apakah anda yakin ingin menghapus video ini?"
            onClose={() => {
              setShowConfirm(false);
              setId(0);
            }}
            onConfirm={() => {
              const videos = sectionProps.data?.body?.youtube;
              const newVideos = videos.filter((_video, index) => index !== id);
              const finalBody = {
                ...sectionProps.data,
                body: {
                  ...sectionProps.data.body,
                  youtube: newVideos,
                },
              };

              finalBody.body = JSON.stringify(finalBody.body);
              const promise = updateHomeSection(
                sectionProps.data.id,
                finalBody
              );

              toast.promise(promise, {
                loading: "Menghapus video...",
                success: "Video berhasil dihapus!",
                error: "Gagal menghapus video!",
              });

              promise
                .then((res) => {
                  if (res.status === 200) {
                    getContent();
                  }
                })
                .catch((err) => {
                  console.error(err);
                });

              setShowConfirm(false);
              setId(null);
            }}
          />,
          document.body
        )}
      <BasicModal
        isOpen={isOpen}
        onClose={onClose}
        header="Update Video"
        scrollBehavior="inside"
      >
        {
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            {
              <InputField
                extra="mb-3"
                label={`
                    Link Youtube*`}
                id={`youtube`}
                type="text"
                name={`youtube`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Link Youtube harus diisi!`,
                }}
              />
            }
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                Update
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => onClose()}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        }
      </BasicModal>
    </>
  );
};

export default YoutubeData;
