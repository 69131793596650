import Card from "components/card";
import { Trash } from "react-feather";
import { FaPlus } from "react-icons/fa";
import { MdHighlight } from "react-icons/md";
import { Link } from "react-router-dom";
import NoThumbnail from "assets/img/dashboards/no-thumbnail.jpg";

const Template = ({
  id,
  icon,
  img,
  title,
  subtitle,
  viewUrl,
  createUrl,
  updateUrl,
  removeData,
  highlightData,
  type,
}) => {
  return (
    <Card extra="flex-col flex-grow items-center rounded-[20px] px-5 justify-between">
      <div className="flex w-full flex-1 justify-between">
        <div className="flex w-full flex-row overflow-hidden">
          {img !== "" && img !== undefined && (
            <div className="flex h-[90px] w-full max-w-[120px] flex-row items-center">
              <img
                src={img}
                alt={title}
                className="h-full rounded-lg object-cover object-center"
              />
            </div>
          )}
          {icon !== undefined && (
            <div className="flex h-[90px] w-auto flex-row items-center">
              <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-brand-400">
                  {icon}
                </span>
              </div>
            </div>
          )}

          <div className="h-50 ml-4 mr-[18px] flex min-w-0 flex-col justify-center">
            <p
              className="truncate font-dm text-sm font-medium capitalize text-gray-600"
              dangerouslySetInnerHTML={{ __html: title }}
            ></p>
            <h4 className="truncate text-xl font-bold text-navy-700 dark:text-white">
              {subtitle}
            </h4>
          </div>
        </div>

        <div className="flex items-center gap-3 sm-max:mb-3 sm-max:w-full">
          {viewUrl !== undefined && (
            <Link to={viewUrl} className="sm-max:w-full sm-max:flex-1">
              <button
                className={`max-w-fit whitespace-nowrap rounded-full bg-white py-3 px-4 text-sm font-medium text-brand-500 transition duration-200 hover:underline active:underline dark:bg-transparent dark:text-brand-400 dark:hover:underline dark:active:text-brand-400 sm-max:w-full sm-max:max-w-none `}
              >
                Lihat Data
              </button>
            </Link>
          )}
          {createUrl !== undefined && (
            <Link to={createUrl} className="sm-max:w-full sm-max:flex-1">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-4 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none`}
              >
                <FaPlus /> Data
              </button>
            </Link>
          )}
          {removeData !== undefined && (
            <div
              className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-red-100 py-3 px-4 text-sm font-medium text-red-600 transition duration-200 hover:text-red-700 dark:bg-red-500 dark:text-red-400 dark:hover:text-red-300 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
            >
              <button
                className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                onClick={() => removeData(id)}
              />
              <Trash width={18} />
            </div>
          )}
          {highlightData !== undefined && (
            <div
              className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-yellow-500 py-3 px-4 text-sm font-medium text-navy-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
            >
              <button
                className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                onClick={() => highlightData(id)}
              />
              <MdHighlight width={18} />
              Highlight
            </div>
          )}
          {updateUrl !== undefined &&
            (typeof updateUrl === "string" ? (
              <Link to={updateUrl} className="sm-max:w-full sm-max:flex-1">
                <button
                  className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none`}
                >
                  Edit
                </button>
              </Link>
            ) : (
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => updateUrl()}
              >
                Edit
              </button>
            ))}
        </div>
      </div>
      {type === "template-1" && (
        <div className="flex w-full flex-1 items-center justify-between pb-8">
          <div className="flex flex-col">
            <h5 className="font-semibold uppercase text-gray-600">
              Small Subtitle
            </h5>
            <h1 className="text-4xl font-bold capitalize text-navy-700">
              Big Title Here
            </h1>
            <div className="mt-3 mb-5 h-[6px] w-40 bg-yellow-400" />
            <p className="text-gray-700">
              Some description that not too long for explaining the section goes
              here! You can type anything here for free
            </p>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex flex-col gap-3">
              <div className="border-2 border-dashed border-gray-300">
                <img src={NoThumbnail} alt="No Thumbnail" />
              </div>
              <div className="border-2 border-dashed border-gray-300">
                <img src={NoThumbnail} alt="No Thumbnail" />
              </div>
            </div>
            <div className="flex">
              <div className="border-2 border-dashed border-gray-300">
                <img src={NoThumbnail} alt="No Thumbnail" />
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "template-2" && (
        <div className="flex w-full flex-1 items-center justify-between pb-8">
          <div className="flex flex-col">
            <h5 className="font-semibold uppercase text-gray-600">
              Small Subtitle
            </h5>
            <h1 className="text-4xl font-bold capitalize text-navy-700">
              Big Title Here
            </h1>
            <div className="mt-3 mb-5 h-[6px] w-40 bg-yellow-400" />
          </div>
          <div className="flex items-center gap-3">
            <div className="flex">
              <div className="h-44 overflow-hidden border-2 border-dashed border-gray-300 object-cover object-center">
                <img
                  src={NoThumbnail}
                  alt="No Thumbnail"
                  className="h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "template-3" && (
        <div className="flex w-full flex-1 flex-col items-center justify-center bg-[#0A3265] p-5 pb-8 text-center">
          <h1 className="text-4xl font-bold capitalize text-white opacity-90">
            Big Title Here
          </h1>
          <div className="mt-3 mb-5 h-[6px] w-40 bg-yellow-400" />
          <p className="text-left text-white opacity-75">
            Some description that not too long for explaining the section goes
            here! You can type anything here for free
          </p>
          <div className="mt-5 flex items-center gap-3">
            <div className="h-full w-full border-2 border-dashed border-gray-300">
              <img
                src={NoThumbnail}
                alt="No Thumbnail"
                className="h-14 w-36 object-cover object-center"
              />
            </div>
            <div className="h-full w-full border-2 border-dashed border-gray-300">
              <img
                src={NoThumbnail}
                alt="No Thumbnail"
                className="h-14 w-36 object-cover object-center"
              />
            </div>
            <div className="h-full w-full border-2 border-dashed border-gray-300">
              <img
                src={NoThumbnail}
                alt="No Thumbnail"
                className="h-14 w-36 object-cover object-center"
              />
            </div>
          </div>
        </div>
      )}
      {type === "template-4" && (
        <div className="my-3 flex w-full flex-1 flex-col items-center justify-center gap-5 bg-[#0A3265] p-5 pb-8 text-center">
          <div>
            <h5 className="font-bold uppercase text-yellow-500">
              Small Subtitle
            </h5>
            <h1 className="text-4xl font-bold capitalize text-white opacity-90">
              Big Title Here
            </h1>
          </div>
          <div className="flex justify-between gap-3">
            <div className="relative h-full w-full border-2 border-dashed border-gray-300">
              <div className="absolute -left-5 top-0 h-4 w-24 bg-yellow-500" />
              <img
                src={NoThumbnail}
                alt="No Thumbnail"
                className="h-64 w-full object-cover object-center"
              />
            </div>
            <div className="flex flex-col text-left">
              <div className="flex flex-1">
                <p className="border border-white p-2 text-white">
                  Some description that not too long for explaining the section
                  goes here!
                </p>
                <p className="border border-white p-2 text-white">
                  Some description that not too long for explaining the section
                  goes here!
                </p>
              </div>
              <div className="flex flex-1">
                <p className="border border-white p-2 text-white">
                  Some description that not too long for explaining the section
                  goes here!
                </p>
                <p className="border border-white p-2 text-white">
                  Some description that not too long for explaining the section
                  goes here!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "template-5" && (
        <div className="my-3 flex w-full flex-1 flex-col items-center justify-center gap-5 bg-[#0A3265] p-5 pb-8 text-center">
          <div>
            <h5 className="font-bold uppercase text-yellow-500">
              Small Subtitle
            </h5>
            <h1 className="text-4xl font-bold capitalize text-white opacity-90">
              Big Title Here
            </h1>
          </div>
          <div className="flex flex-col items-center justify-between gap-5">
            <div className="relative h-56 w-56 rounded-full bg-yellow-500">
              <div className="absolute top-1/2 left-1/2 h-32 w-32 -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#0A3265]" />
            </div>
            <div className="flex gap-3">
              <p className="text-white opacity-80">Label</p>
              <p className="text-white opacity-80">Label</p>
              <p className="text-white opacity-80">Label</p>
            </div>
          </div>
        </div>
      )}
      {type === "template-6" && (
        <div className="my-3 flex w-full flex-1 items-center justify-center gap-16 bg-white p-5 pb-8">
          <div className="flex flex-col items-center justify-between gap-5">
            <div className="relative h-56 w-56 rounded-full bg-yellow-500">
              <div className="absolute top-1/2 left-1/2 h-32 w-32 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white" />
            </div>
            <div className="flex gap-3">
              <p className="text-navy-700 opacity-80">Label</p>
              <p className="text-navy-700 opacity-80">Label</p>
              <p className="text-navy-700 opacity-80">Label</p>
            </div>
          </div>
          <div className="flex flex-col items-end text-right">
            <h5 className="font-bold uppercase text-gray-700">
              Small Subtitle
            </h5>
            <h1 className="text-4xl font-bold capitalize text-navy-700 opacity-90">
              Big Title Here
            </h1>
            <div className="mt-3 mb-5 h-[6px] w-40 bg-yellow-400" />
          </div>
        </div>
      )}
    </Card>
  );
};

export default Template;
