import React, { useState } from "react";
import ElementHandlerContext from ".";

const ElementHandlerProvider = (props) => {
  const [deleteStatus, setDeleteStatus] = useState(false);

  const handleDeleteStatus = (val) => {
    setDeleteStatus(val);
  };

  return (
    <ElementHandlerContext.Provider
      value={{ ...props, deleteStatus, handleDeleteStatus }}
    >
      {props.children}
    </ElementHandlerContext.Provider>
  );
};

export default ElementHandlerProvider;
