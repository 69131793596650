import { Spinner } from "@chakra-ui/react";
import Card from "components/card";
import { Trash } from "react-feather";
import { FaPlus } from "react-icons/fa";
import { MdHighlight } from "react-icons/md";
import { Link } from "react-router-dom";

const Widget = ({
  id,
  icon,
  img,
  title,
  subtitle,
  viewUrl,
  createUrl,
  updateUrl,
  removeData,
  highlightData,
  isLoading,
}) => {
  return (
    <Card extra="!flex-row sm-max:!flex-col flex-grow items-center rounded-[20px] px-5 justify-between">
      <div className="flex w-full flex-row overflow-hidden">
        {img !== "" && img !== undefined && (
          <div className="flex h-[90px] w-full max-w-[120px] flex-row items-center">
            <img
              src={img}
              alt={title}
              className="h-full rounded-lg object-cover object-center"
            />
          </div>
        )}
        {icon !== undefined && (
          <div className="flex h-[90px] w-auto flex-row items-center">
            <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
              <span className="flex items-center text-brand-500 dark:text-brand-400">
                {icon}
              </span>
            </div>
          </div>
        )}

        <div className="h-50 ml-4 mr-[18px] flex min-w-0 flex-col justify-center">
          <p
            className="truncate font-dm text-sm font-medium capitalize text-gray-600"
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
          <h4 className="truncate text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        </div>
      </div>

      {!isLoading ? (
        <div className="flex items-center gap-3 sm-max:mb-3 sm-max:w-full">
          {viewUrl !== undefined && (
            <Link to={viewUrl} className="sm-max:w-full sm-max:flex-1">
              <button
                className={`max-w-fit whitespace-nowrap rounded-full bg-white py-3 px-4 text-sm font-medium text-brand-500 transition duration-200 hover:underline active:underline dark:bg-transparent dark:text-brand-400 dark:hover:underline dark:active:text-brand-400 sm-max:w-full sm-max:max-w-none `}
              >
                Lihat Data
              </button>
            </Link>
          )}
          {createUrl !== undefined && (
            <Link to={createUrl} className="sm-max:w-full sm-max:flex-1">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-4 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none`}
              >
                <FaPlus /> Data
              </button>
            </Link>
          )}
          {removeData !== undefined && (
            <div
              className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-red-100 py-3 px-4 text-sm font-medium text-red-600 transition duration-200 hover:text-red-700 dark:bg-red-500 dark:text-red-400 dark:hover:text-red-300 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
            >
              <button
                className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                onClick={() => removeData(id)}
              />
              <Trash width={18} />
            </div>
          )}
          {/* {highlightData !== undefined && (
            <div
              className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-yellow-500 py-3 px-4 text-sm font-medium text-navy-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
            >
              <button
                className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                onClick={() => highlightData(id)}
              />
              <MdHighlight width={18} />
              Highlight
            </div>
          )} */}
          {updateUrl !== undefined &&
            (typeof updateUrl === "string" ? (
              <Link to={updateUrl} className="sm-max:w-full sm-max:flex-1">
                <button
                  className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none`}
                >
                  Edit
                </button>
              </Link>
            ) : (
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => updateUrl()}
              >
                Edit
              </button>
            ))}
        </div>
      ) : (
        <div className="flex items-center gap-3 sm-max:mb-3 sm-max:w-full">
          <Spinner className="h-6 w-6 text-brand-500 dark:text-brand-400" />
        </div>
      )}
    </Card>
  );
};

export default Widget;
