import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import React from "react";

const Jumbotron = (props) => {
  const { register, errors, existingImage, dropFile, removeFile, file } = props;

  return (
    <div className="flex items-start justify-between gap-3">
      <div className="flex flex-1 flex-col gap-5">
        <div className="flex flex-col gap-3">
          <InputField
            extra="mb-3"
            label={`Subtitle (EN)*`}
            id={`subtitle_en`}
            type="text"
            name={`subtitle_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Subtitle (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3"
            label={`Subtitle (ID)*`}
            id={`subtitle_id`}
            type="text"
            name={`subtitle_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Subtitle (ID) harus diisi!`,
            }}
          />
        </div>
        <div className="flex flex-col gap-3">
          <InputField
            extra="mb-3"
            label={`Title (EN)*`}
            id={`title_en`}
            type="text"
            name={`title_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3"
            label={`Title (ID)*`}
            id={`title_id`}
            type="text"
            name={`title_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (ID) harus diisi!`,
            }}
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        {existingImage && (
          <div className="relative m-3 my-3 flex w-full max-w-xs items-center justify-center rounded-xl border-2 border-gray-200">
            <img
              src={
                existingImage === undefined
                  ? "https://via.placeholder.com/120x100?text=No+Image"
                  : process.env.REACT_APP_API_ENDPOINT +
                    "/images/" +
                    existingImage
              }
              alt={
                existingImage !== undefined &&
                process.env.REACT_APP_API_ENDPOINT + "/images/" + existingImage
              }
              className="rounded-lg"
            />
          </div>
        )}
        <div className="flex w-full items-center gap-3">
          <div className="flex flex-1 flex-col gap-3">
            <DropzoneFile
              text="Upload file"
              accept={{
                "image/jpeg": [".jpg", ".jpeg", ".png"],
                "image/svg+xml": [".svg"],
              }}
              dropFile={dropFile}
              removeFile={removeFile}
              file={file}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;
