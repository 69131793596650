import React from "react";

// Libs
import { useDisclosure } from "@chakra-ui/hooks";
import { TableActionButton } from "components/buttons/TableActionButton";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";

// APIs
import { getAllPageContent, getPageContent } from "api/PageAPI";
import { updateImageList } from "api/ImageListAPI";
import { uploadImages } from "api/ImageAPI";

// Components & Views
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import InputField from "components/fields/InputField";
import DropzoneFile from "components/dropzone/DropzoneFile";
import { ConfirmAlert } from "components/modal/ConfirmAlert";

const columnsData = [
  {
    Header: "FOTO/GAMBAR",
    accessor: "img",
  },
  {
    Header: "TITLE (EN)",
    accessor: "en",
  },
  {
    Header: "TITLE (ID)",
    accessor: "id",
  },
];

const ImageLists = () => {
  const { slug, default_id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [targetEl, setTargetEl] = React.useState([]);
  const [originalData, setOriginalData] = React.useState({});
  const [file, setFile] = React.useState(undefined);
  const [sectionProps, setSectionProps] = React.useState({});
  const [progress, setProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = React.useState(null);
  const [confirm, setConfirm] = React.useState({
    show: false,
    data: null,
  });
  const [action, setAction] = React.useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Refactor object data to array data
  const refactorData = (data) => {
    return data.id?.map((_value, index) => ({
      id: data.id[index],
      en: data.en[index],
      img: data.img[index],
    }));
  };

  const getTargetPage = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = await getAllPageContent();
    return allPageData.data.data.find(
      (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
    );
  };

  const showAllData = async () => {
    setLoading(true);
    const targetPage = await getTargetPage();
    getPageContent(targetPage.id)
      .then((res) => {
        const targetEl = res.data.data.contents.filter((item) => {
          return (
            item.name === "image_lists" && item.id === parseInt(default_id)
          );
        });
        setSectionProps(targetEl[0]);
        setOriginalData(targetEl[0]?.body);
        setTargetEl(refactorData(targetEl[0]?.body));
        setLoading(false);
        reset({});
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
      });
  };

  const dropFile = (file) => {
    setFile(file);
  };

  const removeFile = () => {
    setFile(undefined);
  };

  const showModal = (action, data) => {
    setAction(action);
    action === "adding" && reset({});
    if (action === "updating") {
      setId(data?.row.index);
      reset({
        en: data?.row.original.en,
        id: data?.row.original.id,
      });
    }
    onOpen();
  };

  const deleteData = (data) => {
    setAction("deleting");
    setConfirm({
      show: true,
      data: data.row.index,
    });
  };

  const onSubmit = async (data) => {
    let imgId = null;
    let finalBody = {
      id: sectionProps.id,
      name: sectionProps.name,
      order: sectionProps.order,
      page_id: sectionProps.page_id,
      body: {
        ...originalData,
      },
    };

    if (file) {
      setIsUploading(true);

      const imageBody = {
        images: [file],
      };

      try {
        const res = await uploadImages(imageBody, setProgress);
        imgId = res.data.data.data[0];
        setIsUploading(false);
      } catch (err) {
        setIsUploading(false);

        console.error(err);
        toast.error("Gagal mengunggah gambar!");
      }
    }

    if (action === "adding") {
      const id = originalData?.id ? [...originalData.id, data.id] : [data.id];
      const en = originalData?.en ? [...originalData.en, data.en] : [data.en];
      const img = originalData?.img ? [...originalData.img, imgId] : [imgId];

      finalBody.body = {
        id,
        en,
        img,
      };
    } else if (action === "updating") {
      finalBody.body = {
        id: originalData.id.map((item, index) =>
          index === id ? data.id : item
        ),
        en: originalData.en.map((item, index) =>
          index === id ? data.en : item
        ),
        img: originalData.img.map((item, index) =>
          index === id ? imgId || originalData.img[id] || item[id] : item
        ),
      };
    } else if (action === "deleting") {
      finalBody.body = {
        id: originalData.id.filter((_, index) => index !== id),
        en: originalData.en.filter((_, index) => index !== id),
        img: originalData.img.filter((_, index) => index !== id),
      };
    }

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateImageList(sectionProps.id, finalBody);

    toast.promise(promise, {
      pending: "Menambahkan data...",
      success: `Data Image List berhasil di${
        action === "deleting" ? "hapus" : "update"
      }!`,
      error: `Data Image List gagal di${
        action === "deleting" ? "hapus" : "update"
      }!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Terjadi kesalahan!");
      });

    setFile(undefined);
    setAction(null);
    onClose();
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "file-image",
        Header: "File Gambar",
        Cell: (row) => {
          return row.cell.row.values.img ? (
            <img
              src={
                process.env.REACT_APP_API_ENDPOINT +
                "/images/" +
                row.cell.row.values.img
              }
              alt="image-list"
              className="h-full w-28 rounded-lg object-cover object-center"
            />
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  React.useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={targetEl?.length > 0 ? targetEl : []}
        columnsData={columnsData}
        tableTitle={`${slug
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")} Image List`}
        CTACopy="Data"
        isCTALoading={loading}
        disabled={loading}
        addData={() => showModal("adding")}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["img"]}
        loading={loading}
      />
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({
                show: false,
                data: null,
              });
              setAction(null);
            }}
            onConfirm={() => {
              setId(confirm.data);
              onSubmit(confirm.data);
              setConfirm({
                show: false,
                data: null,
              });
            }}
          />,
          document.body
        )}
      <BasicModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          reset({});
          setFile(undefined);
          onClose();
        }}
        header={action === "adding" ? "Tambah Data" : "Update Data"}
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                    transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
              {action === "updating" && originalData.img[id] && (
                <div className="flex items-center">
                  <img
                    src={
                      process.env.REACT_APP_API_ENDPOINT +
                      "/images/" +
                      originalData.img[id]
                    }
                    alt="certificate"
                    className="h-full w-28 rounded-lg object-cover object-center"
                  />
                </div>
              )}
              <DropzoneFile
                text="Upload file"
                accept={{
                  "image/jpeg": [".jpg", ".jpeg", ".png"],
                  "image/svg+xml": [".svg"],
                }}
                dropFile={dropFile}
                removeFile={removeFile}
                file={file}
              />
              <InputField
                extra="mb-3 w-full"
                label={`Title (EN)*`}
                id={`en`}
                type="text"
                name={`en`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Title (EN) harus diisi!`,
                }}
              />
              <InputField
                extra="mb-3 w-full"
                label={`Title (ID)*`}
                id={`id`}
                type="text"
                name={`id`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Title (ID) harus diisi!`,
                }}
              />
            </div>
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                type="submit"
              >
                {action === "adding" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  reset({});
                  setFile(undefined);
                  onClose();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default ImageLists;
