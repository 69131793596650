import { useDisclosure } from "@chakra-ui/hooks";
import { createBasicInput } from "api/BasicInputAPI";
import { updateHomeSection } from "api/HomeAPI";
import { getHomeContent } from "api/PageAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import InputField from "components/fields/InputField";
import BasicModal from "components/modal/BasicModal";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import Radio from "components/radio";
import TableCard from "components/table/TableCard";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import DatalistInput, { useComboboxControls } from "react-datalist-input";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { countries } from "variables/countries";
import { regions } from "variables/regions";

const columnsData = [
  {
    Header: "COUNTRY",
    accessor: "region",
  },
  {
    Header: "VALUE",
    accessor: "value",
  },
];

const RegionsData = () => {
  const section = "coverage-area";
  const pageId = 1;
  const [sectionId, setSectionId] = useState(null);
  const [order, setOrder] = useState(null);
  const [isRegion, setIsRegion] = useState(null);
  const [datalist, setDatalist] = useState([]);
  const [action, setAction] = useState(null);
  const [index, setIndex] = useState(null);
  const [datalistVal, setDatalistVal] = useState("");
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [confirm, setConfirm] = useState({
    show: false,
    data: null,
  });
  const [payload, setPayload] = useState({
    value: 0,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Modal
  const datalistRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isExpanded, setIsExpanded, setValue, value } = useComboboxControls({
    isExpanded: false,
  });

  const items = useMemo(
    () =>
      datalist.map((option) => ({
        id: option.name,
        value: option.name,
        ...option, // pass along any other properties to access in your onSelect callback
      })),
    [datalist]
  );

  const showModal = (action, data) => {
    setAction(action);
    if (action === "updating") {
      const index = data.row.index;
      const tempData = data.data[index];
      setIsRegion(tempData.type === "region");
      setDatalist(tempData.type === "region" ? regions : countries);
      setValue(tempData.region);
      setIndex(index);
      setPayload({
        region: tempData.region,
        id: tempData.id,
        type: tempData.type,
      });
      reset({
        value: tempData.value,
      });
    }
    onOpen();
  };

  const deleteData = (data) => {
    const index = data.row.index;
    setConfirm({
      show: true,
      data: index,
    });
  };

  const onSubmit = (data) => {
    let finalBody = {
      page_id: pageId,
      section_id: sectionId,
      order: order,
      name: content.name,
      body: {
        ...content.data,
      },
    };

    if (action !== "updating") {
      finalBody = {
        ...finalBody,
        body: {
          ...finalBody.body,
          coverage: Array.isArray(content.data?.coverage)
            ? [...content.data.coverage]
            : [],
        },
      };

      if (!Number.isInteger(data)) {
        finalBody.body.coverage.push({
          ...payload,
          value: data.value,
        });
      }
    } else {
      finalBody.body.coverage.splice(index, 1, {
        ...payload,
        value: data.value,
      });

      finalBody = {
        ...finalBody,
        body: {
          ...finalBody.body,
          coverage: Array.isArray(content.data?.coverage)
            ? [...content.data.coverage]
            : [],
        },
      };
    }

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateHomeSection(sectionId, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200) {
          getContent();
          onClose();
          setDatalistVal("");
          setValue("");
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });

    onClose();
    setIndex(null);
    reset({});
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "percentage",
        Header: "PERCENTAGE",
        Cell: (row) => {
          return row.cell.row.original.value + "%";
        },
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionId(res.data.data[index].id);
      setOrder(res.data.data[index].order);
      setContent({
        loading: false,
        name: res.data.data[index].name,
        data: res.data.data[index].body,
      });
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <TableCard
        tableData={content.data?.coverage || []}
        columnsData={columnsData}
        tableTitle={"Data Wilayah Persebaran"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["value"]}
        loading={content.loading}
        isCTALoading={content.loading}
        disabled={content.loading}
      />
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data Wilayah Persebaran"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({
                show: false,
                data: null,
              });
            }}
            onConfirm={() => {
              content.data?.coverage?.splice(confirm.data, 1);
              setContent({
                ...content,
                data: {
                  ...content.data,
                  coverage: content.data?.coverage,
                },
              });
              onSubmit(confirm.data);
              setConfirm({
                show: false,
                data: null,
              });
            }}
          />,
          document.body
        )}
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          setIsRegion(null);
          reset({});
          setValue("");
          onClose();
        }}
        header="Update Data"
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <div className="flex gap-3">
              <label className="flex cursor-pointer items-center gap-2 font-medium">
                <Radio
                  id="isRegion"
                  name="isRegion"
                  checked={isRegion || false}
                  onChange={() => {
                    setIsRegion(true);
                    setPayload({
                      ...payload,
                      type: "region",
                    });
                    setDatalist(regions);
                    datalistRef.current.focus();
                  }}
                />{" "}
                Region
              </label>
              <label className="flex cursor-pointer items-center gap-2 font-medium">
                <Radio
                  id="isRegion"
                  name="isRegion"
                  checked={(isRegion !== null && !isRegion) || false}
                  onChange={() => {
                    setIsRegion(false);
                    setPayload({
                      ...payload,
                      type: "country",
                    });
                    setDatalist(countries);
                    datalistRef.current.focus();
                  }}
                />{" "}
                Country
              </label>
            </div>
            <DatalistInput
              ref={datalistRef}
              value={value}
              setValue={setValue}
              label="Select country/region"
              showLabel={false}
              items={items}
              isExpanded={isExpanded}
              onSelect={(item) => {
                setPayload({
                  ...payload,
                  id: item.id,
                  region: item.name,
                });
                setDatalistVal(item);
                setIsExpanded(true);
              }}
              className={`${isRegion !== null ? "block" : "hidden"}`}
              isExpandedClassName="max-h-60 overflow-y-scroll"
            />
            {isRegion === null && (
              <p className="font-medium text-gray-500">
                Pilih salah satu terlebih dahulu
              </p>
            )}
            <InputField
              extra={`${isRegion !== null ? "block" : "hidden"} mb-3`}
              label="Percentage*"
              placeholder="1-100"
              id="value"
              type="number"
              step="0.01"
              name="value"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Presentase harus diisi!",
                pattern: {
                  // pattern for float
                  value: /^(?:[1-9]\d*|0)?(?:\.\d+)?$/,
                  message: "Presentase harus diantara 1-100",
                },
              }}
            />
          </div>
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 ${
                isRegion === null && "cursor-not-allowed opacity-50"
              }`}
              type="submit"
              disabled={isRegion === null}
            >
              Simpan
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                setIsRegion(null);
                reset({});
                setValue("");
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default RegionsData;
