import axiosInstance from "./config";

export const createText = (body) => {
  return axiosInstance.post("/texts", body);
};

export const updateText = (id, body) => {
  return axiosInstance.post(`/texts/update/${id}`, body);
};

export const deleteText = (id) => {
  return axiosInstance.get(`/texts/delete/${id}`);
};
