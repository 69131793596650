import axiosInstance from "./config";

export const createMemberCard = (body) => {
  return axiosInstance.post("/member-cards", body);
};

export const updateMemberCard = (id, body) => {
  return axiosInstance.post(`/member-cards/update/${id}`, body);
};

export const deleteMemberCard = (id) => {
  return axiosInstance.get(`/member-cards/delete/${id}`);
};
