import axiosInstance from "./config";

export const showAllCertificate = () => {
  return axiosInstance.get("/certificates");
};

export const showCertificate = (id) => {
  return axiosInstance.get(`/certificates/${id}`);
};

export const createCertificate = (body) => {
  return axiosInstance.post("/certificates", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCertificate = (id) => {
  return axiosInstance.get(`/certificates/delete/${id}`);
};

export const updateCertificate = (id, body) => {
  return axiosInstance.post(`/certificates/update/${id}`, body);
};
