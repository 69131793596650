import axiosInstance from "./config";

export const createMapChart = (body) => {
  return axiosInstance.post("/map-charts", body);
};

export const updateMapChart = (id, body) => {
  return axiosInstance.post(`/map-charts/update/${id}`, body);
};

export const deleteMapChart = (id) => {
  return axiosInstance.get(`/map-charts/delete/${id}`);
};
