import axiosInstance from "./config";

export const createTemplate = (body) => {
  return axiosInstance.post("/templates", body);
};

export const updateTemplate = (id, body) => {
  return axiosInstance.post(`/templates/update/${id}`, body);
};

export const showTemplate = (id) => {
  return axiosInstance.get(`/templates/${id}`);
};

export const deleteTemplate = (id) => {
  return axiosInstance.get(`/templates/delete/${id}`);
};
