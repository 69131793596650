import axiosInstance from "./config";

export const createLink = (body) => {
  return axiosInstance.post("/links", body);
};

export const updateLink = (id, body) => {
  return axiosInstance.post(`/links/update/${id}`, body);
};

export const deleteLink = (id) => {
  return axiosInstance.get(`/links/delete/${id}`);
};
