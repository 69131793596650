import React from "react";

// Libs
import { useDisclosure } from "@chakra-ui/hooks";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// APIs
import { getAllPageContent } from "api/PageAPI";
import { getPageContent } from "api/PageAPI";

import Radio from "components/radio";
import TableCard from "components/table/TableCard";
import BasicModal from "components/modal/BasicModal";
import InputField from "components/fields/InputField";
import DatalistInput, { useComboboxControls } from "react-datalist-input";
import { TableActionButton } from "components/buttons/TableActionButton";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import { countries } from "variables/countries";
import { regions } from "variables/regions";
import { updateMapChart } from "api/MapChartAPI";

const columnsData = [
  {
    Header: "COUNTRY",
    accessor: "region",
  },
  {
    Header: "VALUE",
    accessor: "value",
  },
];

const MapChart = () => {
  const section = "map_chart";
  const pageId = 1;
  const location = useLocation();
  const { default_id } = useParams();
  const [sectionId, setSectionId] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  const [isRegion, setIsRegion] = React.useState(null);
  const [datalist, setDatalist] = React.useState([]);
  const [action, setAction] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [targetEl, setTargetEl] = React.useState([]);
  const [originalData, setOriginalData] = React.useState({});
  const [sectionProps, setSectionProps] = React.useState({});
  const [datalistVal, setDatalistVal] = React.useState("");
  const [content, setContent] = React.useState({
    loading: true,
    data: null,
  });
  const [confirm, setConfirm] = React.useState({
    show: false,
    data: null,
  });
  const [payload, setPayload] = React.useState({
    value: 0,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Modal
  const datalistRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isExpanded, setIsExpanded, setValue, value } = useComboboxControls({
    isExpanded: false,
  });

  const items = React.useMemo(
    () =>
      datalist.map((option) => ({
        id: option.name,
        value: option.name,
        ...option, // pass along any other properties to access in your onSelect callback
      })),
    [datalist]
  );

  const showModal = (action, data) => {
    setAction(action);
    if (action === "updating") {
      const index = data.row.index;
      const tempData = data.data[index];
      setIsRegion(tempData.type === "region");
      setDatalist(tempData.type === "region" ? regions : countries);
      setValue(tempData.region);
      setIndex(index);
      setPayload({
        region: tempData.region,
        id: tempData.id,
        type: tempData.type,
      });
      reset({
        value: tempData.value,
      });
    }
    onOpen();
  };

  const deleteData = (data) => {
    const index = data.row.index;
    setAction("deleting");
    setConfirm({
      show: true,
      data: index,
    });
  };

  const onSubmit = (data) => {
    let finalBody = {
      page_id: sectionProps.page_id,
      order: sectionProps.order,
      name: sectionProps.name,
      id: sectionProps.id,
      body: [...originalData],
    };
    const newData = {
      ...payload,
      type: isRegion ? "region" : "country",
      value: data.value,
    };

    if (action === "adding") {
      finalBody = {
        ...finalBody,
        body: [...finalBody.body, newData],
      };
    }
    if (action === "deleting") {
      originalData?.splice(confirm.data, 1);
      finalBody = {
        ...finalBody,
        body: [...originalData],
      };
    }
    if (action === "updating") {
      finalBody.body.splice(index, 1, {
        ...payload,
        value: data.value,
      });
    }

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateMapChart(sectionProps.id, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200) {
          showAllData();
          onClose();
          setDatalistVal("");
          setValue("");
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });

    onClose();
    setIndex(null);
    reset({});
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "percentage",
        Header: "PERCENTAGE",
        Cell: (row) => {
          return row.cell.row.original.value + "%";
        },
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  const getTargetPage = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = await getAllPageContent();
    return allPageData.data.data.find(
      (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
    );
  };

  const showAllData = async () => {
    setLoading(true);
    const targetPage = await getTargetPage();
    getPageContent(targetPage.id)
      .then((res) => {
        const targetEl = res.data.data.contents.filter((item) => {
          return item.name === "map_charts" && item.id === parseInt(default_id);
        });

        setSectionProps(targetEl[0]);
        setOriginalData(targetEl[0]?.body);
        setLoading(false);
        reset({});
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
      });
  };

  React.useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={originalData?.length > 0 ? originalData : []}
        columnsData={columnsData}
        tableTitle={"Data Wilayah"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["value"]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data Wilayah"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({
                show: false,
                data: null,
              });
            }}
            onConfirm={() => {
              onSubmit(confirm.data);
              setConfirm({
                show: false,
                data: null,
              });
            }}
          />,
          document.body
        )}
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          setIsRegion(null);
          reset({});
          setValue("");
          onClose();
        }}
        header="Update Data"
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <div className="flex gap-3">
              <label className="flex cursor-pointer items-center gap-2 font-medium">
                <Radio
                  id="isRegion"
                  name="isRegion"
                  checked={isRegion || false}
                  onChange={() => {
                    setIsRegion(true);
                    setPayload({
                      ...payload,
                      type: "region",
                    });
                    setDatalist(regions);
                    datalistRef.current.focus();
                  }}
                />{" "}
                Region
              </label>
              <label className="flex cursor-pointer items-center gap-2 font-medium">
                <Radio
                  id="isRegion"
                  name="isRegion"
                  checked={(isRegion !== null && !isRegion) || false}
                  onChange={() => {
                    setIsRegion(false);
                    setPayload({
                      ...payload,
                      type: "country",
                    });
                    setDatalist(countries);
                    datalistRef.current.focus();
                  }}
                />{" "}
                Country
              </label>
            </div>
            <DatalistInput
              ref={datalistRef}
              value={value}
              setValue={setValue}
              label="Select country/region"
              showLabel={false}
              items={items}
              isExpanded={isExpanded}
              onSelect={(item) => {
                setPayload({
                  ...payload,
                  id: item.id,
                  region: item.name,
                });
                setDatalistVal(item);
                setIsExpanded(true);
              }}
              className={`${isRegion !== null ? "block" : "hidden"}`}
              isExpandedClassName="max-h-60 overflow-y-scroll"
            />
            {isRegion === null && (
              <p className="font-medium text-gray-500">
                Pilih salah satu terlebih dahulu
              </p>
            )}
            <InputField
              extra={`${isRegion !== null ? "block" : "hidden"} mb-3`}
              label="Percentage*"
              placeholder="1-100"
              id="value"
              type="number"
              step="0.01"
              name="value"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Presentase harus diisi!",
                pattern: {
                  // pattern for float
                  value: /^(?:[1-9]\d*|0)?(?:\.\d+)?$/,
                  message: "Presentase harus diantara 1-100",
                },
              }}
            />
          </div>
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 ${
                isRegion === null && "cursor-not-allowed opacity-50"
              }`}
              type="submit"
              disabled={isRegion === null}
            >
              Simpan
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                setIsRegion(null);
                reset({});
                setValue("");
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default MapChart;
