import React from "react";
import TextField from "components/fields/TextField";

const Text = (props) => {
  const { updateName, errors, register } = props;

  return (
    <>
      <TextField
        extra="mb-3"
        label={`${
          updateName.charAt(0).toUpperCase() + updateName.slice(1)
        } (EN)*`}
        id={`${updateName}_en`}
        type="text"
        name={`${updateName}_en`}
        errors={errors}
        register={register}
        rows={10}
        defaultValue={""}
        validationSchema={{
          required: `${
            updateName.charAt(0).toUpperCase() + updateName.slice(1)
          } (EN) harus diisi!`,
        }}
      />
      <TextField
        extra="mb-3"
        label={`${
          updateName.charAt(0).toUpperCase() + updateName.slice(1)
        } (ID)*`}
        id={`${updateName}_id`}
        type="text"
        name={`${updateName}_id`}
        errors={errors}
        register={register}
        rows={10}
        defaultValue={""}
        validationSchema={{
          required: `${
            updateName.charAt(0).toUpperCase() + updateName.slice(1)
          } (ID) harus diisi!`,
        }}
      />
    </>
  );
};

export default Text;
