import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import React from "react";

const MemberCards = (props) => {
  const { errors, register, dropImage, removeImage, file, existingImages } =
    props;

  return (
    <div className="flex gap-3 md-max:flex-col">
      <div className="flex flex-1 flex-col gap-5 overflow-hidden">
        {existingImages && (
          <div className="relative box-border flex h-40 w-full items-center justify-center rounded-xl border-2 border-gray-200">
            <img
              src={
                existingImages === undefined
                  ? "https://via.placeholder.com/120x100?text=No+Image"
                  : process.env.REACT_APP_API_ENDPOINT +
                    "/images/" +
                    existingImages
              }
              alt={
                existingImages !== undefined &&
                process.env.REACT_APP_API_ENDPOINT + "/images/" + existingImages
              }
              className="h-full rounded-lg"
            />
          </div>
        )}
        <DropzoneFile
          text="Upload Image"
          accept={{
            "image/jpeg": [".jpg", ".jpeg", ".png"],
          }}
          dropFile={dropImage}
          removeFile={removeImage}
          file={file}
        />
      </div>
      <div className="flex flex-1 flex-col gap-3">
        <InputField
          extra="mb-3"
          label="Nama*"
          id="name"
          type="text"
          name="name"
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{
            required: `Nama tidak boleh kosong!`,
          }}
        />
        <InputField
          extra="mb-3"
          label="Posisi (ID)*"
          id="position_id"
          type="text"
          name="position_id"
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{
            required: `Posisi (ID) tidak boleh kosong!`,
          }}
        />
        <InputField
          extra="mb-3"
          label="Posisi (EN)*"
          id="position_en"
          type="text"
          name="position_en"
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{
            required: `Posisi (EN) tidak boleh kosong!`,
          }}
        />
      </div>
    </div>
  );
};

export default MemberCards;
