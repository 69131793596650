import React from "react";
import CardContainer from "components/card/CardContainer";
import Widget from "components/widget/Widget";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";
import { showAllCompanyNews } from "api/CompanyNewsAPI";

const HomeProductData = () => {
  const section = "news";
  const pageId = 1;
  const [items, setItems] = React.useState([]);
  const [sectionProp, setSectionProp] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");

  const getAllNews = () => {
    showAllCompanyNews()
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal memuat data produk. Silahkan coba lagi!");
      });
  };

  const handleSearchKeyword = (e) => {
    setSearchValue(e.target.value);
  };

  const debounceResults = React.useMemo(() => {
    return debounce(handleSearchKeyword, 500);
  }, []);

  const handleHighlight = (id) => {
    const highlightedId = sectionProp.body || [];

    if (highlightedId?.length < 2) highlightedId.push(id);
    if (highlightedId?.length === 2) {
      highlightedId.pop();
      highlightedId.unshift(id);
    }

    const finalBody = {
      section_id: sectionProp.id,
      name: sectionProp.name,
      page_id: pageId,
      order: sectionProp.order,
      body: highlightedId,
    };

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateHomeSection(sectionProp.id, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil dihighlight!",
      error: "Gagal dihighlihgt!",
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getContent();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionProp(res.data.data[index]);
    });
  };

  React.useEffect(() => {
    getAllNews();
    getContent();

    return () => {
      debounceResults.cancel();
    };
  }, []);

  return (
    <CardContainer
      title="Berita di Home Page"
      description="Berita yang akan ditampilkan di Home Page"
      searchPlaceholder="Cari berita"
      withSearchBar={true}
      onSearch={(e) => {
        e.persist();
        debounceResults(e);
      }}
    >
      <div className="flex flex-col gap-3">
        {items.map((item, index) => (
          <div key={index}>
            <Widget
              title={`${item?.category === 1 ? "Company News" : "CSR"}`}
              subtitle={item.body?.titleId}
              img={
                process.env.REACT_APP_API_ENDPOINT +
                "/images/" +
                item?.body?.img[0]
              }
              highlightData={() => {
                handleHighlight(item.id);
              }}
            />
          </div>
        ))}
      </div>
    </CardContainer>
  );
};

export default HomeProductData;
