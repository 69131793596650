import { Spinner } from "@chakra-ui/react";
import Card from "components/card";
import Switch from "components/switch";
import React, { useCallback, useMemo } from "react";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const TableCard = (props) => {
  const isArrayOfArrays = (arr) => {
    if (Array.isArray(arr)) {
      if (Array.isArray(arr[0])) {
        return true;
      } else {
        return false;
      }
    }
  };

  // Table
  const data = useMemo(() => [...props.tableData], [props.tableData]);
  const columns = useMemo(() => [...props.columnsData], [props.columnsData]);

  const tableHooks = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => {
      return props.extendTableHooks(
        columns,
        props.updateData,
        props.deleteData
      );
    });
  });

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: props.hiddenColumns,
        pageSize: 10,
        pageIndex: 0,
      },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // End Table

  return (
    <div>
      <div className="mt-5 h-full">
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              {props.tableTitle}
            </div>
            <div className="flex items-center gap-5">
              {props.hasSwitch && (
                <div className="flex items-start gap-2">
                  <Switch
                    id={props.switchId}
                    checked={props.switchChecked}
                    onChange={props.switchOnChange}
                    name={props.switchName}
                  />
                  <label
                    htmlFor={props.switchId}
                    className="text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {props.switchLabel}
                  </label>
                </div>
              )}
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-brand-500 py-3 px-6 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none ${
                  props.disabled ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={props.disabled}
                onClick={() => props.addData("adding")}
              >
                {props.isCTALoading ? (
                  <Spinner size="sm" className="mx-5 my-0.5" />
                ) : (
                  <div className="flex items-center gap-2">
                    <FaPlus />
                    {props.CTACopy}
                  </div>
                )}
              </button>
            </div>
          </header>

          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={index}
                        className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700"
                      >
                        <div className="flex w-full justify-between pr-10 text-xs tracking-wide text-gray-600">
                          {column.render("Header")}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {!props.loading ? (
                data.length > 0 ? (
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={index}>
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                className="pt-[14px] pb-[20px] sm:text-[14px]"
                                {...cell.getCellProps()}
                                key={index}
                              >
                                {cell.column.Header === "ACTION" ? (
                                  cell.render("Cell")
                                ) : cell.column.Header === "URL" ||
                                  cell.column.Header === "LINK" ? (
                                  <a
                                    href={cell.value}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-sm font-bold text-brand-500 underline dark:text-white"
                                  >
                                    {cell.render("Cell")}
                                  </a>
                                ) : cell.column.Header === "THUMBNAIL" ||
                                  cell.column.Header === "IMAGE" ? (
                                  <img
                                    src={cell.value}
                                    alt={cell.value}
                                    className="h-14 w-24 rounded-md bg-gray-300 object-cover"
                                  />
                                ) : cell.column.type === "free" ? (
                                  !isArrayOfArrays(props.tableData) ? (
                                    cell.render("Cell")
                                  ) : (
                                    row.original[index]
                                  )
                                ) : (
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {!isArrayOfArrays(props.tableData)
                                      ? cell.render("Cell")
                                      : row.original[index]}
                                  </p>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="" colSpan={columns.length + 1}>
                        <p className="mt-8 text-center font-bold text-navy-700 dark:text-white">
                          Data Kosong
                        </p>
                      </td>
                    </tr>
                  </tbody>
                )
              ) : (
                <tbody>
                  <tr>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={150} />
                    </td>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={150} />
                    </td>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={175} />
                    </td>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={175} />
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={150} />
                    </td>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={150} />
                    </td>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={175} />
                    </td>
                    <td className="pt-[14px] pb-[20px]">
                      <Skeleton height={20} borderRadius={50} width={175} />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          {data.length > 0 && (
            <div className="pagination mt-2 flex items-center justify-between">
              <div className="flex items-center gap-1">
                Rows per page:
                <select
                  value={pageSize}
                  className="flex h-10 items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-2 text-center outline-none dark:!border-white/10 dark:text-white"
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <span>
                  Page
                  <strong className="mx-1">
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <span className="flex items-center gap-1">
                  | Go to page:
                  <input
                    type="number"
                    className="flex h-10 items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-2 text-center outline-none dark:!border-white/10 dark:text-white"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "50px" }}
                  />
                </span>
              </div>
              <div className="flex gap-2">
                <button
                  className="rounded-lg bg-white p-2 text-brand-500 text-opacity-90"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <BiChevronsLeft className="text-xl" />
                </button>
                <button
                  className="rounded-lg bg-brand-500 p-2 text-white text-opacity-90"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiChevronLeft className="text-xl" />
                </button>
                <button
                  className="rounded-lg bg-brand-500 p-2 text-white text-opacity-90"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiChevronRight className="text-xl" />
                </button>
                <button
                  className="rounded-lg bg-white p-2 text-brand-500 text-opacity-90"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <BiChevronsRight className="text-xl" />
                </button>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

TableCard.defaultProps = {
  hiddenColumns: [],
  CTACopy: `Data`,
};

export default TableCard;
