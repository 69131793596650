import axiosInstance from "./config";

export const createCardList = (body) => {
  return axiosInstance.post("/card-lists", body);
};

export const updateCardList = (id, body) => {
  return axiosInstance.post(`/card-lists/update/${id}`, body);
};

export const showCardList = (id) => {
  return axiosInstance.get(`/card-lists/${id}`);
};

export const deleteCardList = (id) => {
  return axiosInstance.get(`/card-lists/delete/${id}`);
};
