import axiosInstance from "./config";

export const createDivider = (body) => {
  return axiosInstance.post("/dividers", body);
};

export const updateDivider = (id, body) => {
  return axiosInstance.post(`/dividers/update/${id}`, body);
};

export const deleteDivider = (id) => {
  return axiosInstance.get(`/dividers/delete/${id}`);
};
