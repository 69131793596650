import React, { useEffect, useState } from "react";

// Libs
import { useDisclosure } from "@chakra-ui/hooks";
import { TableActionButton } from "components/buttons/TableActionButton";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BiInfoCircle } from "react-icons/bi";

// APIs
import { showProductBySlug } from "api/ProductAPI";
import { updateCardList } from "api/CardListAPI";
import { getPageContent } from "api/PageAPI";
import { getAllPageContent } from "api/PageAPI";

// Components & Views
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import TextField from "components/fields/TextField";
import { createPortal } from "react-dom";
import { ConfirmAlert } from "components/modal/ConfirmAlert";

const columnsData = [
  {
    Header: "VALUES",
    accessor: "body",
  },
];

const ProductCardLists = () => {
  const location = useLocation();
  const { slug, default_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cardList, setCardList] = useState([]);

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState(null);
  const [confirm, setConfirm] = useState({
    show: false,
    data: null,
  });
  const [action, setAction] = useState(null);
  const [sectionProps, setSectionProps] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getTargetPage = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = await getAllPageContent();
    return allPageData.data.data.find(
      (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
    );
  };

  const showAllData = async () => {
    setLoading(true);
    if (slug) {
      showProductBySlug(slug)
        .then((res) => {
          const cardList = res.data.data.contents.filter((item) => {
            return (
              item.name === "card_lists" && item.id === parseInt(default_id)
            );
          });
          setSectionProps(cardList[0]);
          setCardList(cardList[0]?.body);
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    } else {
      const targetPage = await getTargetPage();
      getPageContent(targetPage.id)
        .then((res) => {
          const cardList = res.data.data.contents.filter((item) => {
            return (
              item.name === "card_lists" && item.id === parseInt(default_id)
            );
          });
          setSectionProps(cardList[0]);
          setCardList(cardList[0]?.body);
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    }
  };

  const showModal = (action, data) => {
    reset({
      values: data?.data.join("\n") || cardList[0]?.join("\n"),
    });
    setId(data?.row.index);
    setAction(action);
    onOpen();
  };

  const deleteData = (data) => {
    setAction("deleting");
    setConfirm({
      show: true,
      data: data.row.index,
    });
  };

  const onSubmit = (data) => {
    const oldCardList = cardList;
    const values = data.values
      ? data.values.split(/\r?\n/)
      : cardList[0].values;
    let finalBody = {
      order: sectionProps.order,
      name: sectionProps.name,
      body: [],
    };

    if (slug)
      finalBody = {
        ...finalBody,
        product_id: sectionProps.product_id,
        body: [],
      };
    else
      finalBody = {
        ...finalBody,
        page_id: sectionProps.page_id,
        body: [],
      };

    // add and update
    if (action !== "deleting")
      finalBody = {
        ...finalBody,
        body: values,
      };
    if (action === "deleting") {
      oldCardList.splice(data, 1);
      finalBody = {
        ...finalBody,
        body: oldCardList,
      };
    }

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateCardList(sectionProps.id, finalBody);

    toast.promise(promise, {
      pending: "Menambahkan data...",
      success: `Data Card List berhasil di${
        action === "deleting" ? "hapus" : "update"
      }!`,
      error: `Data Card List gagal di${
        action === "deleting" ? "hapus" : "update"
      }!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });

    setAction(null);
    onClose();
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "values",
        Header: "VALUES",
        Cell: (data) => data.row.original,
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={cardList?.length > 0 ? cardList : []}
        columnsData={columnsData}
        tableTitle={`${(slug || location.pathname.split("/")[2])
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")} Card Lists`}
        CTACopy="Data"
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["body"]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({
                show: false,
                data: null,
              });
              setAction(null);
            }}
            onConfirm={() => {
              onSubmit(confirm.data);
              setConfirm({
                show: false,
                data: null,
              });
            }}
          />,
          document.body
        )}
      <BasicModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          reset({});
          onClose();
        }}
        header={action === "adding" ? "Tambah Data" : "Update Data"}
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2 text-navy-400">
                <BiInfoCircle />
                <p>
                  Gunakan{" "}
                  <code className="rounded-sm bg-gray-200 px-1 font-semibold">
                    Enter
                  </code>{" "}
                  untuk memisahkan tiap Value / Isi Card
                </p>
              </div>
              <TextField
                extra="mb-3"
                label={`Value / Isi Card*`}
                id={`values`}
                type="text"
                name={`values`}
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
                validationSchema={{
                  required: `Value / Isi Card harus diisi!`,
                }}
              />
            </div>
          }
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
              type="submit"
            >
              {action === "adding" ? "Tambah" : "Update"}
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                reset({});
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default ProductCardLists;
