import React, { useEffect, useState } from "react";
import CardContainer from "components/card/CardContainer";
import Widget from "components/widget/Widget";
import { MdDescription, MdShortText, MdTextFields } from "react-icons/md";
import BasicModal from "components/modal/BasicModal";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import TextField from "components/fields/TextField";
import { toast } from "react-toastify";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";
import { TiSocialInstagram, TiSocialYoutube } from "react-icons/ti";

const HomeSocialData = () => {
  const section = "social_media";
  const pageId = 1;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [sectionId, setSectionId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [order, setOrder] = useState(null);
  const [updateName, setUpdateName] = useState("");
  const [tmpOldImg, setTmpOldImg] = useState([]);
  const [payload, setPayload] = useState({
    body: {
      file1: undefined,
      file2: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const showUpdateModal = (name) => {
    setUpdateName(name);
    reset({
      [name + "_en"]: content.data[name + "_en"],
      [name + "_id"]: content.data[name + "_id"],
      cdn_instagram_link: content.data.cdn_instagram_link,
    });

    onOpen();
  };

  const onSubmit = async (data) => {
    let promise = null;
    let finalBody = {
      page_id: pageId,
      order: order,
      name: content.name,
      body: {
        ...content.data,
        ...data,
      },
    };

    finalBody.body = JSON.stringify(finalBody.body);
    promise = updateHomeSection(sectionId, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200) {
          getContent();
          onClose();
          reset();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });

    onClose();
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionId(res.data.data[index].id);
      setOrder(res.data.data[index].order);
      setContent({
        loading: false,
        name: res.data.data[index].name,
        data: res.data.data[index].body,
      });
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <CardContainer
        title="Media Sosial di Home Page"
        description="Informasi yang ditampilkan pada bagian media sosial di home page."
      >
        <div className="flex flex-col gap-3">
          <Widget
            isLoading={content?.loading}
            title="Subtitle"
            subtitle={content.data && content.data.subtitle_id}
            icon={<MdShortText />}
            updateUrl={() => showUpdateModal("subtitle")}
          />
          <Widget
            isLoading={content?.loading}
            title="Title"
            subtitle={content.data && content.data.title_id}
            icon={<MdTextFields />}
            updateUrl={() => showUpdateModal("title")}
          />
          <Widget
            isLoading={content?.loading}
            title="Text/Description"
            subtitle={content.data && content.data.text_id}
            icon={<MdDescription />}
            updateUrl={() => showUpdateModal("text")}
          />
          <Widget
            isLoading={content?.loading}
            title="link"
            subtitle="Instagram"
            icon={<TiSocialInstagram />}
            updateUrl={() => showUpdateModal("link")}
          />
          <Widget
            isLoading={content?.loading}
            title="link"
            subtitle="Youtube"
            icon={<TiSocialYoutube />}
            viewUrl="/home-page/youtube"
          />
        </div>
      </CardContainer>
      <BasicModal
        isOpen={isOpen}
        onClose={onClose}
        header="Update Data"
        scrollBehavior="inside"
      >
        {
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            {updateName === "text" || updateName === "description" ? (
              <>
                <TextField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN)*`}
                  id={`${updateName}_en`}
                  type="text"
                  name={`${updateName}_en`}
                  errors={errors}
                  register={register}
                  rows={10}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (EN) harus diisi`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID)*`}
                  id={`${updateName}_id`}
                  type="text"
                  name={`${updateName}_id`}
                  errors={errors}
                  register={register}
                  rows={10}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (ID) harus diisi`,
                  }}
                />
              </>
            ) : updateName === "link" ? (
              <InputField
                extra="mb-3"
                label="Link Lightwidget Instagram"
                id="cdn_instagram_link"
                type="text"
                name="cdn_instagram_link"
                errors={errors}
                placeholder="https://cdn.lightwidget.com/widgets/..."
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Link instagram harus diisi`,
                }}
              />
            ) : (
              <>
                <InputField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN)*`}
                  id={`${updateName}_en`}
                  type="text"
                  name={`${updateName}_en`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (EN) harus diisi`,
                  }}
                />
                <InputField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID)*`}
                  id={`${updateName}_id`}
                  type="text"
                  name={`${updateName}_id`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (ID) harus diisi`,
                  }}
                />
              </>
            )}
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                Update
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => onClose()}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        }
      </BasicModal>
    </>
  );
};

export default HomeSocialData;
