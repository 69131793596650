import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import { setFormData } from "utils";
import { createBasicInput } from "api/BasicInputAPI";
import { toast } from "react-toastify";
import DropzoneFile from "../../../../components/dropzone/DropzoneFile";
import FormLayout from "views/admin/forms";
import TextField from "components/fields/TextField";
import { useNavigate, useParams } from "react-router-dom";

const HomeAboutForm = () => {
  const pageName = "short-about";
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUpdate, setIsUpdate] = useState(id ? true : false);
  const [itemId, setItemId] = useState(id ? id : null);
  const [payload, setPayload] = useState({
    file1: undefined,
    file2: undefined,
    file3: undefined,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const changePayloadByAttrName = (event) => {
    setPayload((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  // File upload
  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      file: file,
    }));
  };

  const removeFile = () => {
    setPayload({
      ...payload,
      file: undefined,
    });
  };
  // End file upload

  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));
  const onSubmit = () => {
    if (payload.body.file === undefined) return toast.error("File is required");
    else {
      const body = setFormData(payload);
      let promise = null;

      if (isUpdate) {
        body.append("id", itemId);
        promise = createBasicInput(body);
      } else {
        promise = createBasicInput(body);
      }

      toast.promise(resolveAfter3Sec, {
        pending: "Loading...",
        success:
          pageName.charAt(0).toUpperCase() +
          pageName.slice(1) +
          " berhasil di" +
          (isUpdate ? "-update" : "tambahkan") +
          "!",
        error: "Error while retrieving data",
      });

      promise
        .then((res) => {
          navigate(`/home-page/${pageName}`, { replace: true });
          if (res.status === 200) {
            reset();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <FormLayout
      title="Home Carousel"
      description="Form untuk mengatur home carousel"
      children={
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-3 rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <div className="flex flex-col items-start gap-3">
              <div className="w-full ">
                <InputField
                  extra="mb-3"
                  label="Subtitle (EN)*"
                  placeholder="Sub Judul Pendek Bahasa Inggris"
                  id="subtitleEn"
                  type="text"
                  name="subtitleEn"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Subtitle (EN) harus diisi",
                    onChange: (e) => {
                      changePayloadByAttrName(e);
                    },
                  }}
                />
                <InputField
                  extra="mb-3"
                  label="Title (EN)*"
                  placeholder="Judul Pendek Bahasa Inggris"
                  id="titleEn"
                  type="text"
                  name="titleEn"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Title (EN) harus diisi",
                    onChange: (e) => {
                      changePayloadByAttrName(e);
                    },
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Deksripsi (EN)*"
                  placeholder="Deskripsi Panjang Bahasa Inggris"
                  id="descriptionEn"
                  cols="20"
                  rows="5"
                  name="descriptionEn"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Deskripsi (EN) harus diisi",
                    onChange: (e) => {
                      changePayloadByAttrName(e);
                    },
                  }}
                />
                <div className="my-10 w-full border border-dashed border-gray-300" />
                <InputField
                  extra="mb-3"
                  label="Subtitle (ID)*"
                  placeholder="Sub Judul Pendek Bahasa Indonesia"
                  id="subtitleId"
                  type="text"
                  name="subtitleId"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Subtitle (ID) harus diisi",
                    onChange: (e) => {
                      changePayloadByAttrName(e);
                    },
                  }}
                />
                <InputField
                  extra="mb-3"
                  label="Title (ID)*"
                  placeholder="Judul Pendek Bahasa Indonesia"
                  id="titleId"
                  type="text"
                  name="titleId"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Title (ID) harus diisi",
                    onChange: (e) => {
                      changePayloadByAttrName(e);
                    },
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Deksripsi (ID)*"
                  placeholder="Deskripsi Panjang Bahasa Indonesia"
                  id="descriptionId"
                  cols="20"
                  rows="5"
                  name="descriptionId"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Deskripsi (ID) harus diisi",
                    onChange: (e) => {
                      changePayloadByAttrName(e);
                    },
                  }}
                />
              </div>
              <div className="flex w-full items-center gap-3">
                <div className="flex flex-1 flex-col gap-3">
                  <DropzoneFile
                    text="Upload file"
                    accept={{
                      "image/jpeg": [".jpg", ".jpeg", ".png"],
                    }}
                    dropFile={dropFile}
                    removeFile={removeFile}
                    file={payload.file1}
                  />
                  <DropzoneFile
                    text="Upload file"
                    accept={{
                      "image/jpeg": [".jpg", ".jpeg", ".png"],
                    }}
                    dropFile={dropFile}
                    removeFile={removeFile}
                    file={payload.file2}
                  />
                </div>
                <div className="flex-1">
                  <DropzoneFile
                    text="Upload file"
                    accept={{
                      "image/jpeg": [".jpg", ".jpeg", ".png"],
                    }}
                    dropFile={dropFile}
                    removeFile={removeFile}
                    file={payload.file3}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="linear max-w-max rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save Data
              </button>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default HomeAboutForm;
