import { useDisclosure } from "@chakra-ui/hooks";
import { createCSR, updateCSR, deleteCSR, showAllCSR } from "api/CSRAPI";
import { uploadImages } from "api/ImageAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Trash, Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getYoutubeID } from "utils";
import { getVideoPreviewData } from "utils/API";

const columnsData = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "GAMBAR",
    accessor: "body.img",
  },
  {
    Header: "JUDUL (ID)",
    accessor: "body.titleId",
  },
  {
    Header: "JUDUL (EN)",
    accessor: "body.titleEn",
  },
  {
    Header: "DESKRIPSI ISI (ID)",
    accessor: "body.descriptionId",
  },
  {
    Header: "DESKRIPSI ISI (EN)",
    accessor: "body.descriptionEn",
  },
  {
    Header: "YOUTUBE",
    accessor: "body.youtube",
  },
];

const CSRData = () => {
  const [loading, setLoading] = useState(true);
  const newsCategory = 1; // CSR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState({});
  const [action, setAction] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [payload, setPayload] = useState({
    body: {
      img: undefined,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropImage = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        img: file,
      },
    }));
  };

  const removeImage = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        img: undefined,
      },
    });
  };
  // End file upload

  const showAllData = () => {
    setLoading(true);
    showAllCSR()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 404)
          toast.error("Data tidak dapat ditemukan!");
      });
  };

  const showModal = async (action, data) => {
    setAction(action);
    if (action === "updating") {
      setTempData({
        id: data?.row.original.id,
        titleId: data?.row.original.body?.titleId,
        titleEn: data?.row.original.body?.titleEn,
        descriptionId: data?.row.original.body?.descriptionId,
        descriptionEn: data?.row.original.body?.descriptionEn,
        img: data?.row.original.body?.img
          ? process.env.REACT_APP_API_ENDPOINT +
            "/images/" +
            data?.row.original.body?.img
          : null,
        imgId: data?.row.original.body?.img
          ? data?.row.original.body?.img
          : null,
        youtube: data?.row.original.body?.youtube
          ? data?.row.original.body?.youtube
          : null,
      });
      reset({
        date: data?.row.original?.date.split(" ")[0],
        titleId: data?.row.original.body?.titleId,
        titleEn: data?.row.original.body?.titleEn,
        descriptionId: data?.row.original.body?.descriptionId,
        descriptionEn: data?.row.original.body?.descriptionEn,
        youtube: data?.row.original.body?.youtube,
      });
      if (data.body?.youtube) {
        const youtubeId = getYoutubeID(data.body?.youtube);
        const newPreview = await getVideoPreviewData(youtubeId);

        if (videos.length === 0) {
          setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
          setPreview([...preview, newPreview]);
        }
      }
    } else reset({});
    onOpen();
  };

  const closeModal = () => {
    reset();
    setPreview([]);
    setVideos([]);
    setThumbnails([]);
    setTempData({});
  };

  const deleteData = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden 
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  deleteCSR(data.row.original.id)
                    .then((res) => {
                      if (res.status === 200) {
                        showAllData();
                        toast.success("Berhasil dihapus!");
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error(err.response.data.message);
                    });
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setIsUploading(true);
    onOpen();

    let body = {};
    let promise = null;
    let finalBody = null;

    if (payload.body.img) {
      body = {
        images: [payload.body.img],
      };
      await uploadImages(body, setProgress)
        .then((res) => {
          delete payload.body.img;

          finalBody = {
            category: newsCategory,
            date: data.date,
            body: {
              ...data,
              img: [res.data.data.data[0]],
            },
          };
        })
        .catch((err) => {
          onClose();
          toast.error(err.response.data.data.message);
          closeModal();
        });
    } else {
      if (tempData.img)
        finalBody = {
          category: newsCategory,
          date: data.date,
          body: {
            ...data,
            img: tempData?.imgId,
          },
        };
      else
        finalBody = {
          category: newsCategory,
          date: data.date,
          body: {
            ...data,
            img: null,
          },
        };
    }

    finalBody.body = JSON.stringify(finalBody.body);

    action === "adding" && (promise = createCSR(finalBody));
    action === "updating" && (promise = updateCSR(tempData.id, finalBody));

    toast.promise(promise, {
      pending: "Loading...",
      success: `Berhasil ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      error: `Gagal ${action === "adding" ? "ditambahkan" : "di-update"}!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          setPayload({
            ...{},
            body: {
              img: undefined,
            },
          });
          onClose();
          closeModal();
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsUploading(false);
        toast.error(err.response.data.message);
      });

    setIsUploading(false);
    onClose();
    closeModal();
  };

  // Youtube Video Handler
  const [videos, setVideos] = useState([]);
  const [preview, setPreview] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);

  const handleInputVideo = async (e) => {
    let youtubeUrl = e.target.value;
    let youtubeId = getYoutubeID(youtubeUrl);

    if (youtubeId !== "") {
      const newPreview = await getVideoPreviewData(youtubeId);
      const { runtime, youtube_video_id } = newPreview;

      if (videos.length === 0) {
        setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
        setPreview([...preview, newPreview]);
        setVideos([...videos, runtime, youtube_video_id]);
      } else {
        // Check if video already exist in the state
        if (
          videos?.find((video) => video.youtube_video_id === youtubeId) ===
          undefined
        ) {
          setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
          setPreview([...preview, newPreview]);
          setVideos([...videos, runtime, youtube_video_id]);
        }
      }
      e.target.value = "";
    }
  };

  const handleDeleteVideo = (index) => {
    const newPreview = preview.filter((_preview, i) => i !== index);
    const newVideos = videos.filter((_video, i) => i !== index);
    const newThumbnails = thumbnails.filter((_thumbnail, i) => i !== index);
    setPreview(newPreview);
    setVideos(newVideos);
    setThumbnails(newThumbnails);
    reset({
      youtube: null,
    });
  };
  // End Youtube Video Handler

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "file-image",
        Header: "GAMBAR",
        Cell: (row) => {
          return row.cell.row.values["body.img"] ? (
            <img
              src={
                process.env.REACT_APP_API_ENDPOINT +
                "/images/" +
                row.cell.row.values["body.img"]
              }
              alt="certificate"
              className="h-full w-28 rounded-lg object-cover object-center"
            />
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      {
        id: "titles",
        Header: "JUDUL",
        Cell: (row) => {
          return `(ID) ${row.cell.row.values["body.titleId"]}  - (EN) ${row.cell.row.values["body.titleEn"]}`;
        },
      },
      // Enable this if you want to show description
      // {
      //   id: "descriptions",
      //   Header: "ISI BERITA",
      //   Cell: (row) => {
      //     return `(ID) ${row.cell.row.values["body.descriptionId"]}  - (EN) ${row.cell.row.values["body.descriptionEn"]}`;
      //   },
      // },
      ...columns,
      {
        id: "youtube-url",
        Header: "YOUTUBE",
        Cell: (row) => {
          return row.cell.row.values["body.youtube"] ? (
            <span className="inline-block w-[200px]">
              <a
                href={row.cell.row.values["body.youtube"]}
                target="_blank"
                rel="noreferrer"
                className="block truncate text-brand-500 underline hover:text-brand-600"
              >
                Lihat Video
              </a>
            </span>
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={data}
        columnsData={columnsData}
        tableTitle={"Corporate Social Responsibility"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={[
          "body.titleId",
          "body.titleEn",
          "body.descriptionId",
          "body.descriptionEn",
          "body.img",
          "id",
          "body.youtube",
        ]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      <BasicModal
        scrollBehavior="inside"
        size="6xl"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          closeModal();
        }}
        header={
          action === "adding" ? "Tambah Berita (CSR)" : "Update Berita (CSR)"
        }
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
              <div className="flex flex-1 flex-col gap-5">
                {action === "updating" && tempData.img && (
                  <div className="flex items-center">
                    <img
                      src={tempData.img}
                      alt="certificate"
                      className="h-full w-28 rounded-lg object-cover object-center"
                    />
                    <button
                      type="button"
                      className="ml-3 text-red-500 hover:text-red-600"
                      onClick={() => {
                        setPayload({
                          ...payload,
                          body: {
                            ...payload.body,
                            img: undefined,
                          },
                        });
                        setTempData({
                          ...tempData,
                          img: undefined,
                        });
                      }}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                )}
                <DropzoneFile
                  text="Upload Image"
                  accept={{
                    "image/jpeg": [".jpg", ".jpeg", ".png"],
                  }}
                  dropFile={dropImage}
                  removeFile={removeImage}
                  file={payload.body.img}
                />
              </div>
              <InputField
                extra="mb-3"
                label="Tanggal Berita*"
                id="date"
                type="date"
                name="date"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Tanggal berita tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Judul Berita (ID)*"
                id="titleId"
                type="text"
                name="titleId"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Judul berita (ID) tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Judul Berita (EN)*"
                id="titleEn"
                type="text"
                name="titleEn"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Judul berita (EN) tidak boleh kosong!`,
                }}
              />
              <TextField
                extra="mb-3"
                label="Deskripsi Berita (ID)"
                placeholder="Isi dengan deskripsi berita"
                id="descriptionId"
                type="text"
                name="descriptionId"
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
              />
              <TextField
                extra="mb-3"
                label="Deskripsi Berita (EN)"
                placeholder="Isi dengan deskripsi berita"
                id="descriptionEn"
                type="text"
                name="descriptionEn"
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
              />
            </div>
            <div className="flex w-full flex-col gap-3">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-1 flex-col gap-3">
                  {preview?.length > 0 ? (
                    preview?.map((preview, index) => {
                      return (
                        <div key={index}>
                          <div className="mb-4 grid grid-cols-6">
                            <div className="col-span-2 mb-3 mr-3">
                              <img
                                src={
                                  thumbnails?.length === 0
                                    ? `https://via.placeholder.com/640x360.png?text=No+Thumbnail`
                                    : thumbnails[index]
                                }
                                alt={thumbnails[index]}
                                className="h-full w-full rounded-lg object-cover"
                              />
                            </div>
                            <div className="col-span-4 flex flex-col justify-between">
                              <div>
                                <h3 className="mb-1 w-full text-lg font-bold">
                                  {preview?.title}
                                </h3>
                                <p
                                  className="w-full overflow-hidden text-gray-700"
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {preview?.subtitle}
                                </p>
                              </div>
                              <div className="flex w-full items-end justify-between">
                                <div>
                                  <p className="mb-0 text-sm font-semibold text-gray-700">
                                    Durasi
                                  </p>
                                  <p className="font-semibold">
                                    {preview?.runtime}
                                  </p>
                                </div>
                                <div
                                  className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-red-100 py-2 px-3 text-sm font-medium text-red-600 transition duration-200 hover:text-red-700 dark:bg-red-500 dark:text-red-400 dark:hover:text-red-300 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                                >
                                  <button
                                    className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                                    onClick={() => handleDeleteVideo(index)}
                                  />
                                  <Trash width={16} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="my-3 text-center font-semibold text-gray-600">
                      Belum ada video yang ditambahkan. Silahkan tambahkan video
                    </p>
                  )}
                </div>
                <div className="w-full flex-1">
                  <InputField
                    extra="mb-3"
                    label="Link Youtube"
                    placeholder="https://www.youtube.com/watch?v=..."
                    id="youtube"
                    type="text"
                    name="youtube"
                    errors={errors}
                    register={register}
                    disabled={preview?.length >= 1}
                    validationSchema={{
                      onChange: (e) => {
                        handleInputVideo(e);
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                type="submit"
              >
                {action === "adding" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  onClose();
                  closeModal();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default CSRData;
