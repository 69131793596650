import React from "react";
import InputField from "components/fields/InputField";

const Template5 = (props) => {
  const { register, errors, handleChart } = props;

  return (
    <div className="flex flex-col items-start justify-between gap-3">
      <div className="flex w-full flex-1 flex-col gap-5">
        <div className="flex gap-3">
          <InputField
            extra="mb-3 w-full"
            label={`Subtitle (EN)*`}
            id={`subtitle_en`}
            type="text"
            name={`subtitle_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Subtitle (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3 w-full"
            label={`Subtitle (ID)*`}
            id={`subtitle_id`}
            type="text"
            name={`subtitle_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Subtitle (ID) harus diisi!`,
            }}
          />
        </div>
        <div className="flex flex-col gap-3">
          <InputField
            extra="mb-3"
            label={`Title (EN)*`}
            id={`title_en`}
            type="text"
            name={`title_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3"
            label={`Title (ID)*`}
            id={`title_id`}
            type="text"
            name={`title_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (ID) harus diisi!`,
            }}
          />
        </div>
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center justify-between gap-5">
            <div className="relative h-40 w-40 rounded-full bg-yellow-500">
              <div className="absolute top-1/2 left-1/2 h-24 w-24 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white" />
            </div>
            <div className="flex gap-3">
              <p className="text-brand-500 opacity-80">Label</p>
              <p className="text-brand-500 opacity-80">Label</p>
              <p className="text-brand-500 opacity-80">Label</p>
            </div>
          </div>
          <button
            className="linear rounded-[20px] border border-brand-500 bg-transparent px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-brand-700 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            type="button"
            onClick={handleChart}
          >
            Edit Data Chart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Template5;
