import React from "react";
import Radio from "components/radio";
import TextField from "components/fields/TextField";
import InputField from "components/fields/InputField";
import { BiInfoCircle } from "react-icons/bi";

const List = (props) => {
  const { errors, register, listType, setListType } = props;

  const handleListType = (type) => setListType(type);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-2 text-navy-400">
        <BiInfoCircle />
        <p>
          Gunakan{" "}
          <code className="rounded-sm bg-gray-200 px-1 font-semibold">
            Enter
          </code>{" "}
          untuk memisahkan tiap List
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <InputField
          extra="mb-3"
          label={`Title (EN)`}
          id={`title_en`}
          type="text"
          name={`title_en`}
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{}}
        />
        <InputField
          extra="mb-3"
          label={`Title (ID)`}
          id={`title_id`}
          type="text"
          name={`title_id`}
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{}}
        />
      </div>
      <div className="flex flex-col gap-3">
        <TextField
          extra="mb-3"
          label={`List (EN)*`}
          id={`en`}
          type="text"
          name={`en`}
          errors={errors}
          register={register}
          rows={10}
          defaultValue={""}
          validationSchema={{
            required: `List (EN) harus diisi!`,
          }}
        />
        <TextField
          extra="mb-3"
          label={`List (ID)*`}
          id={`id`}
          type="text"
          name={`id`}
          errors={errors}
          register={register}
          rows={10}
          defaultValue={""}
          validationSchema={{
            required: `List (ID) harus diisi!`,
          }}
        />
      </div>
      <div className="flex gap-3">
        <label className="flex cursor-pointer items-center gap-2 font-medium">
          <Radio
            id="type"
            name="type"
            checked={listType === "ordered"}
            onChange={() => {
              handleListType("ordered");
            }}
          />{" "}
          Ordered List
        </label>
        <label className="flex cursor-pointer items-center gap-2 font-medium">
          <Radio
            id="type"
            name="type"
            checked={listType === "unordered"}
            onChange={() => {
              handleListType("unordered");
            }}
          />{" "}
          Unordered List
        </label>
      </div>
    </div>
  );
};

export default List;
