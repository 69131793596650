import React, { useEffect, useState } from "react";

// Libs
import { useDisclosure } from "@chakra-ui/hooks";
import { TableActionButton } from "components/buttons/TableActionButton";
import { confirmAlert } from "react-confirm-alert";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdModeEdit } from "react-icons/md";
import { BiInfoCircle } from "react-icons/bi";

// APIs
import { getPageContent } from "api/PageAPI";
import { getAllPageContent } from "api/PageAPI";
import { showProductBySlug } from "api/ProductAPI";
import { updateTable, showTable } from "api/TableAPI";

// Components & Views
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import TextField from "components/fields/TextField";
import InputField from "components/fields/InputField";

const ProductTables = () => {
  const location = useLocation();
  const { slug, default_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, setAction] = useState(null);
  const [payload, setPayload] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getTargetPage = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = await getAllPageContent();
    return allPageData.data.data.find(
      (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
    );
  };

  const showAllData = async () => {
    setLoading(true);

    if (slug) {
      showProductBySlug(slug)
        .then((res) => {
          const tableData = res.data.data.contents.filter((item) => {
            return item.name === "tables" && item.id === parseInt(default_id);
          });

          setPayload(tableData[0]);
          setTableData(tableData[0]?.body);
          pushToColumn(tableData[0]?.body?.en?.tableHeader);
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    } else {
      const targetPage = await getTargetPage();
      getPageContent(targetPage.id)
        .then((res) => {
          const tableData = res.data.data.contents.filter((item) => {
            return item.name === "tables" && item.id === parseInt(default_id);
          });

          setPayload(tableData[0]);
          setTableData(tableData[0]?.body);
          pushToColumn(tableData[0]?.body?.en?.tableHeader);
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    }
  };

  const showModal = async (action, data) => {
    reset({});
    setAction(action);
    if (action === "updating-header") {
      if (slug) {
        showProductBySlug(slug)
          .then((res) => {
            const tableId = res.data.data.contents.filter((item) => {
              return item.name === "tables" && item.id === parseInt(default_id);
            })[0]?.id;
            showTable(tableId)
              .then((res) => {
                reset({
                  en: res?.data?.data?.body?.en?.tableHeader.join("\n"),
                  id: res?.data?.data?.body?.id?.tableHeader.join("\n"),
                });
              })
              .catch(() => {
                toast.error("Gagal memuat data! Coba beberapa saat lagi");
              });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 404)
              toast.error("Data tidak dapat ditemukan!");
          });
      } else {
        const targetPage = await getTargetPage();
        getPageContent(targetPage.id)
          .then((res) => {
            const tableId = res.data.data.contents.filter((item) => {
              return item.name === "tables" && item.id === parseInt(default_id);
            })[0]?.id;
            showTable(tableId)
              .then((res) => {
                reset({
                  en: res?.data?.data?.body?.en?.tableHeader.join("\n"),
                  id: res?.data?.data?.body?.id?.tableHeader.join("\n"),
                });
              })
              .catch(() => {
                toast.error("Gagal memuat data! Coba beberapa saat lagi");
              });
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 404)
              toast.error("Data tidak dapat ditemukan!");
          });
      }
    } else if (action === "updating-data" || action === "updating") {
      const index = data?.row?.index;
      setDataIndex(index);
      const tempDataId = tableData?.id?.tableBody[index];
      const tempDataEn = tableData?.en?.tableBody[index];
      let tempResetField = {};

      if (tempDataId && tempDataEn)
        columnsData.forEach((item, i) => {
          if (item.accessor === "id") return;
          tempResetField = {
            ...tempResetField,
            [item.accessor + "_id"]: tempDataId[i - 1],
            [item.accessor + "_en"]: tempDataEn[i - 1],
          };
        });

      reset(tempResetField);
    }
    onOpen();
  };

  const deleteData = (data) => {
    const index = data.row.index;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden 
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  setAction("deleting");
                  onSubmit(index);
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const updateTableBody = (tableData, tableBody) => {
    if (tableData && tableData.length > 0) {
      return [...tableData, [...tableBody]];
    } else {
      return [[...tableBody]];
    }
  };

  const onSubmit = (data) => {
    let finalBody = {};
    const tableHeaderEn = data.en
      ? data.en.split(/\r?\n/)
      : tableData.en.tableHeader;
    const tableHeaderId = data.id
      ? data.id.split(/\r?\n/)
      : tableData.id.tableHeader;
    let tableBodyEn = [];
    let tableBodyId = [];

    if (action === "updating-data") {
      Object.entries(data).forEach(([key, value]) => {
        if (key.includes("_en")) {
          tableBodyEn.push(value);
        }
        if (key.includes("_id")) {
          tableBodyId.push(value);
        }
      });
    } else {
      if (tableData?.id?.tableBody && tableData?.en?.tableBody) {
        tableBodyId.push(...tableData.id.tableBody);
        tableBodyEn.push(...tableData.en.tableBody);
      }
    }

    if (action === "updating-data") {
      tableBodyId = updateTableBody(tableData.id.tableBody, tableBodyId);
      tableBodyEn = updateTableBody(tableData.en.tableBody, tableBodyEn);
    }
    // updating each row
    if (action === "updating") {
      tableBodyId[dataIndex] = [];
      tableBodyEn[dataIndex] = [];
      Object.entries(data).filter(([key, value]) => {
        if (key.includes("_id")) tableBodyId[dataIndex].push(value);
      });
      Object.entries(data).filter(([key, value]) => {
        if (key.includes("_en")) tableBodyEn[dataIndex].push(value);
      });
    }
    if (action === "deleting" || Number.isInteger(data)) {
      tableBodyId.splice(data, 1);
      tableBodyEn.splice(data, 1);
    }

    finalBody = {
      order: payload.order,
      body: {
        id: {
          tableHeader: tableHeaderId,
          tableBody: [...tableBodyId],
        },
        en: {
          tableHeader: tableHeaderEn,
          tableBody: [...tableBodyEn],
        },
      },
    };

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateTable(payload.id, finalBody);

    toast.promise(promise, {
      pending: "Menambahkan data...",
      success: "Data Table berhasil diupdate!",
      error: "Data Table gagal diupdate!",
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });

    setAction(null);
    onClose();
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  const pushToColumn = (header) => {
    setColumnsData([
      {
        Header: () => (
          <button
            onClick={() => showModal("updating-header")}
            className="rounded-full bg-brand-500 bg-opacity-20 p-1"
          >
            <MdModeEdit className="text-xl text-brand-500" />
          </button>
        ),
        accessor: "id",
      },
      ...(header || []).map((item) => ({
        Header: item.toUpperCase(),
        accessor: item.toLowerCase(),
      })),
    ]);
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={
          tableData?.en?.tableBody?.length > 0 ? tableData?.en?.tableBody : []
        }
        columnsData={columnsData}
        tableTitle={`Spesifikasi ${
          slug ||
          location.pathname
            .split("/")[2]
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        }`}
        CTACopy="Data"
        addData={() => showModal("updating-data")}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={[]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      <BasicModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          reset({});
          onClose();
        }}
        header={action === "updating-data" ? "Tambah Data" : "Update Data"}
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {action === "updating-data" || action === "updating" ? (
            <div className="flex flex-col gap-3">
              {columnsData.length <= 1 ? (
                <p className="text-center text-lg font-medium text-gray-700">
                  Header belum ditambahkan!
                </p>
              ) : (
                columnsData.map((column, index) => {
                  if (column.accessor === "id") return null;
                  return (
                    <div className="mb-3 flex flex-col gap-3" key={index}>
                      <div className="flex flex-1 gap-3">
                        <InputField
                          extra="mb-3 w-full"
                          label={`${
                            column.accessor.charAt(0).toUpperCase() +
                            column.accessor.slice(1)
                          } (EN)*`}
                          id={column.accessor + "_en"}
                          type="text"
                          name={column.accessor + "_en"}
                          errors={errors}
                          register={register}
                          defaultValue={""}
                          validationSchema={{
                            required: `${
                              column.accessor.charAt(0).toUpperCase() +
                              column.accessor.slice(1)
                            } (EN) harus diisi`,
                          }}
                        />
                        <InputField
                          extra="mb-3 w-full"
                          label={`${
                            column.accessor.charAt(0).toUpperCase() +
                            column.accessor.slice(1)
                          } (ID)*`}
                          id={column.accessor + "_id"}
                          type="text"
                          name={column.accessor + "_id"}
                          errors={errors}
                          register={register}
                          defaultValue={""}
                          validationSchema={{
                            required: `${
                              column.accessor.charAt(0).toUpperCase() +
                              column.accessor.slice(1)
                            } (ID) harus diisi`,
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2 text-navy-400">
                <BiInfoCircle />
                <p>
                  Gunakan{" "}
                  <code className="rounded-sm bg-gray-200 px-1 font-semibold">
                    Enter
                  </code>{" "}
                  untuk memisahkan tiap Header
                </p>
              </div>
              <TextField
                extra="mb-3"
                label={`Header Table (EN)*`}
                id={`en`}
                type="text"
                name={`en`}
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
                validationSchema={{
                  required: `Header Table (EN) harus diisi`,
                }}
              />
              <TextField
                extra="mb-3"
                label={`Header Table (ID)*`}
                id={`id`}
                type="text"
                name={`id`}
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
                validationSchema={{
                  required: `Header Table (ID) harus diisi`,
                }}
              />
            </div>
          )}
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
              type="submit"
            >
              {action === "adding" ? "Tambah" : "Update"}
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                reset({});
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default ProductTables;
