import { useDisclosure } from "@chakra-ui/hooks";
import {
  showAllCareer,
  createCareer,
  deleteCareer,
  updateCareer,
} from "api/CareerAPI";
import { uploadImages } from "api/ImageAPI";
import { toggleCareerPage } from "api/PageAPI";
import { getPageContent } from "api/PageAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import BasicModal from "components/modal/BasicModal";
import Radio from "components/radio";
import TableCard from "components/table/TableCard";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import { BiInfoCircle } from "react-icons/bi";
import { toast } from "react-toastify";

const columnsData = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "GAMBAR",
    accessor: "body.img",
  },
  {
    Header: "JUDUL (ID)",
    accessor: "body.id",
  },
  {
    Header: "JUDUL (EN)",
    accessor: "body.en",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "SALARY",
    accessor: "body.salary",
  },
  {
    Header: "LOKASI",
    accessor: "body.location",
  },
  {
    Header: "CARA APPLY (ID)",
    accessor: "body.how_to_id",
  },
  {
    Header: "CARA APPLY (EN)",
    accessor: "body.how_to_en",
  },
  {
    Header: "RESPONSIBILITIES (ID)",
    accessor: "body.responsibilities_id",
  },
  {
    Header: "RESPONSIBILITIES (EN)",
    accessor: "body.responsibilities_en",
  },
  {
    Header: "REQUIREMENTS (ID)",
    accessor: "body.requirements_id",
  },
  {
    Header: "REQUIREMENTS (EN)",
    accessor: "body.requirements_en",
  },
  {
    Header: "REQUIRED DOCS (ID)",
    accessor: "body.required_docs_id",
  },
  {
    Header: "REQUIRED DOCS (EN)",
    accessor: "body.required_docs_en",
  },
];

const CareerData = () => {
  const [loading, setLoading] = useState(true);
  const newsCategory = 3; // Career
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(null);
  const [tempData, setTempData] = useState({});
  const [action, setAction] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showPage, setShowPage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [payload, setPayload] = useState({
    body: {
      img: undefined,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropImage = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        img: file,
      },
    }));
  };

  const removeImage = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        img: undefined,
      },
    });
  };
  // End file upload

  const handleSwitchChange = () => {
    setShowPage(!showPage);
    const pageCareerId = 18;

    toggleCareerPage()
      .then((res) => {
        toast.success(res.data.data.message);
        getPageContent(pageCareerId)
          .then((res) => {
            toast.success(res.data.data.message);
            setShowPage(parseInt(res.data.data.status) === 1);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showAllData = () => {
    setLoading(true);
    const pageCareerId = 18;

    getPageContent(pageCareerId)
      .then((res) => {
        setShowPage(parseInt(res.data.data.status) === 1);
      })
      .catch((err) => {
        console.error(err);
      });

    showAllCareer()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 404)
          toast.error("Data tidak dapat ditemukan!");
      });
  };

  const showModal = async (action, data) => {
    setAction(action);

    if (action === "updating") {
      const baseData = data.row.original;
      setStatus(baseData.status);
      setTempData({
        id: baseData.id,
        img: baseData.body.img[0],
      });
      reset({
        id: baseData.body.id,
        en: baseData.body.en,
        salary: baseData.body.salary,
        location: baseData.body.location,
        how_to_id: baseData.body.how_to_id,
        how_to_en: baseData.body.how_to_en,
        responsibilities_id: baseData.body.responsibilities_id.join("\n"),
        responsibilities_en: baseData.body.responsibilities_en.join("\n"),
        requirements_id: baseData.body.requirements_id.join("\n"),
        requirements_en: baseData.body.requirements_en.join("\n"),
        required_docs_id: baseData.body.required_docs_id.join("\n"),
        required_docs_en: baseData.body.required_docs_en.join("\n"),
      });
    } else {
      reset({});
    }

    onOpen();
  };

  const closeModal = () => {
    reset();
    setTempData({});
  };

  const deleteData = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden 
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  deleteCareer(data.row.original.id)
                    .then((res) => {
                      if (res.status === 200) {
                        showAllData();
                        toast.success("Berhasil dihapus!");
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error(err.response.data.message);
                    });
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setIsUploading(true);
    onOpen();

    delete data.status;

    let body = {};
    let promise = null;
    let finalBody = null;

    const responsibilities_en = data.responsibilities_en
      ? data.responsibilities_en.split(/\r?\n/)
      : [];
    const responsibilities_id = data.responsibilities_id
      ? data.responsibilities_id.split(/\r?\n/)
      : [];
    const requirements_en = data.requirements_en
      ? data.requirements_en.split(/\r?\n/)
      : [];
    const requirements_id = data.requirements_id
      ? data.requirements_id.split(/\r?\n/)
      : [];
    const required_docs_en = data.required_docs_en
      ? data.required_docs_en.split(/\r?\n/)
      : [];
    const required_docs_id = data.required_docs_id
      ? data.required_docs_id.split(/\r?\n/)
      : [];

    finalBody = {
      category: newsCategory,
      status: status ? 1 : 0,
      body: {
        ...data,
        responsibilities_en,
        responsibilities_id,
        requirements_en,
        requirements_id,
        required_docs_en,
        required_docs_id,
      },
    };

    if (payload.body.img) {
      body = {
        images: [payload.body.img],
      };
      await uploadImages(body, setProgress)
        .then((res) => {
          delete payload.body.img;

          finalBody = {
            ...finalBody,
            body: {
              ...finalBody.body,
              img: res.data.data.data,
            },
          };
        })
        .catch((err) => {
          onClose();
          console.error(err);
          toast.error("Gagal mengunggah gambar!");
          closeModal();
        });
    } else {
      if (tempData.img)
        finalBody = {
          ...finalBody,
          body: {
            ...finalBody.body,
            img: [tempData?.img],
          },
        };
      else
        finalBody = {
          ...finalBody,
          body: {
            ...finalBody.body,
            img: null,
          },
        };
    }

    finalBody.body = JSON.stringify(finalBody.body);

    action === "adding" && (promise = createCareer(finalBody));
    action === "updating" && (promise = updateCareer(tempData.id, finalBody));

    toast.promise(promise, {
      pending: "Loading...",
      success: `Berhasil ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      error: `Gagal ${action === "adding" ? "ditambahkan" : "di-update"}!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          setPayload({
            ...{},
            body: {
              img: undefined,
            },
          });
          onClose();
          closeModal();
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsUploading(false);
        toast.error(err.response.data.message);
      });

    setIsUploading(false);
    onClose();
    closeModal();
  };

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "file-image",
        Header: "GAMBAR",
        Cell: (row) => {
          return row.cell.row.values["body.img"] ? (
            <img
              src={
                process.env.REACT_APP_API_ENDPOINT +
                "/images/" +
                row.cell.row.values["body.img"]
              }
              alt="certificate"
              className="h-full w-28 rounded-lg object-cover object-center"
            />
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      {
        id: "titles",
        Header: "JUDUL LOWONGAN",
        type: "free",
        Cell: (row) => {
          return (
            <div className="flex flex-col text-sm font-bold text-navy-700 dark:text-white">
              <p>(ID) {row.cell.row.values["body.id"]}</p>
              <p>(EN) {row.cell.row.values["body.en"]}</p>
            </div>
          );
        },
      },
      ...columns,
      {
        id: "formatted-salary",
        Header: "SALARY",
        type: "free",
        Cell: (row) => {
          return (
            <div className="flex w-max flex-col text-sm font-bold text-navy-700 dark:text-white">
              <p>
                Rp
                {row.cell.row.values["body.salary"]}
              </p>
            </div>
          );
        },
      },
      {
        id: "formatted-status",
        Header: "STATUS",
        type: "free",
        Cell: (row) => {
          return (
            <div className="flex w-max flex-col text-sm font-bold text-white ">
              <p
                className={`${
                  row.cell.row.values["status"] === "1"
                    ? "bg-green-600"
                    : "bg-gray-600"
                } rounded-2xl px-2 py-1`}
              >
                {row.cell.row.values["status"] === "1" ? "Dibuka" : "Ditutup"}
              </p>
            </div>
          );
        },
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={data}
        columnsData={columnsData}
        tableTitle={"Career"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={[
          "id",
          "body.id",
          "body.en",
          "body.img",
          "status",
          "body.location",
          "body.salary",
          "body.how_to_id",
          "body.how_to_en",
          "body.responsibilities_id",
          "body.responsibilities_en",
          "body.requirements_id",
          "body.requirements_en",
          "body.required_docs_id",
          "body.required_docs_en",
        ]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
        // Switch
        hasSwitch={true}
        switchId="showPage"
        switchName="showPage"
        switchOnChange={handleSwitchChange}
        switchLabel="Show/hide Page"
        switchChecked={showPage}
        // End Switch
      />
      <BasicModal
        scrollBehavior="inside"
        size="6xl"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          closeModal();
        }}
        header={
          action === "adding"
            ? "Tambah Lowongan (Career)"
            : "Update Lowongan (Career)"
        }
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
              <div className="flex flex-1 flex-col gap-5">
                {action === "updating" && tempData.img && (
                  <div className="flex items-center">
                    <img
                      src={
                        process.env.REACT_APP_API_ENDPOINT +
                        "/images/" +
                        tempData.img
                      }
                      alt="certificate"
                      className="h-full w-44 rounded-lg object-cover object-center"
                    />
                    {/* <button
                      type="button"
                      className="ml-3 text-red-500 hover:text-red-600"
                      onClick={() => {
                        setPayload({
                          ...payload,
                          body: {
                            ...payload.body,
                            img: undefined,
                          },
                        });
                        setTempData({
                          ...tempData,
                          img: undefined,
                        });
                      }}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button> */}
                  </div>
                )}
                <DropzoneFile
                  text="Upload Image"
                  accept={{
                    "image/jpeg": [".jpg", ".jpeg", ".png"],
                  }}
                  dropFile={dropImage}
                  removeFile={removeImage}
                  file={payload.body.img}
                />
              </div>
              <InputField
                extra="mb-3"
                label="Judul Lowongan (ID)*"
                id="id"
                type="text"
                name="id"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Judul lowongan (ID) tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Judul Lowongan (EN)*"
                id="en"
                type="text"
                name="en"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Judul lowongan (EN) tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Cara Apply/Melamar (ID)*"
                id="how_to_id"
                type="text"
                name="how_to_id"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Cara Apply/Melamar (ID) tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Cara Apply/Melamar (EN)*"
                id="how_to_en"
                type="text"
                name="how_to_en"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Cara Apply/Melamar (EN) tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Lokasi*"
                id="location"
                type="text"
                name="location"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Lokasi tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Salary/Gaji*"
                id="salary"
                type="text"
                name="salary"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Salary/Gaji tidak boleh kosong!`,
                }}
              />
              <div className="flex flex-col">
                <div className="flex items-center gap-2 text-navy-400">
                  <BiInfoCircle />
                  <p>
                    Gunakan{" "}
                    <code className="rounded-sm bg-gray-200 px-1 font-semibold">
                      Enter
                    </code>{" "}
                    untuk memisahkan tiap Tanggung Jawab
                  </p>
                </div>
                <div className="flex w-full gap-5">
                  <TextField
                    extra="mb-3 w-full resize-none"
                    label="Tanggung Jawab/Responsibilities (ID)"
                    placeholder="Isi dengan tanggung jawab pekerjaan"
                    id="responsibilities_id"
                    type="text"
                    name="responsibilities_id"
                    errors={errors}
                    register={register}
                    rows={10}
                    defaultValue={""}
                  />
                  <TextField
                    extra="mb-3 w-full resize-none"
                    label="Tanggung Jawab/Responsibilities (EN)"
                    placeholder="Isi dengan tanggung jawab pekerjaan"
                    id="responsibilities_en"
                    type="text"
                    name="responsibilities_en"
                    errors={errors}
                    register={register}
                    rows={10}
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-2 text-navy-400">
                  <BiInfoCircle />
                  <p>
                    Gunakan{" "}
                    <code className="rounded-sm bg-gray-200 px-1 font-semibold">
                      Enter
                    </code>{" "}
                    untuk memisahkan tiap Requirement
                  </p>
                </div>
                <div className="flex w-full gap-5">
                  <TextField
                    extra="mb-3 w-full resize-none"
                    label="Requirements (ID)"
                    placeholder="Isi dengan requirements pekerjaan"
                    id="requirements_id"
                    type="text"
                    name="requirements_id"
                    errors={errors}
                    register={register}
                    rows={10}
                    defaultValue={""}
                  />
                  <TextField
                    extra="mb-3 w-full resize-none"
                    label="Requirements (EN)"
                    placeholder="Isi dengan requirements pekerjaan"
                    id="requirements_en"
                    type="text"
                    name="requirements_en"
                    errors={errors}
                    register={register}
                    rows={10}
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-2 text-navy-400">
                  <BiInfoCircle />
                  <p>
                    Gunakan{" "}
                    <code className="rounded-sm bg-gray-200 px-1 font-semibold">
                      Enter
                    </code>{" "}
                    untuk memisahkan tiap Kebutuhan Dokumen
                  </p>
                </div>
                <div className="flex w-full gap-5">
                  <TextField
                    extra="mb-3 w-full resize-none"
                    label="Kebutuhan Dokumen (ID)"
                    placeholder="Isi dengan kebutuhan dokumen"
                    id="required_docs_id"
                    type="text"
                    name="required_docs_id"
                    errors={errors}
                    register={register}
                    rows={10}
                    defaultValue={""}
                  />
                  <TextField
                    extra="mb-3 w-full resize-none"
                    label="Kebutuhan Dokumen (EN)"
                    placeholder="Isi dengan kebutuhan dokumen"
                    id="required_docs_en"
                    type="text"
                    name="required_docs_en"
                    errors={errors}
                    register={register}
                    rows={10}
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <label className="flex cursor-pointer items-center gap-2 font-medium">
                  <Radio
                    id="status"
                    name="status"
                    checked={status}
                    onChange={() => {
                      setStatus(true);
                    }}
                  />{" "}
                  Open/Buka
                </label>
                <label className="flex cursor-pointer items-center gap-2 font-medium">
                  <Radio
                    id="status"
                    name="status"
                    checked={status === false}
                    onChange={() => {
                      setStatus(false);
                    }}
                  />{" "}
                  Closed/Tutup
                </label>
              </div>
            </div>
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                type="submit"
              >
                {action === "adding" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  onClose();
                  closeModal();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default CareerData;
