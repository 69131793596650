export const setFormData = (data) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const convertDuration = (duration) => {
  duration = duration.replace("PT", "");
  const hours = duration?.includes("H")
    ? duration?.split("H")[0].length === 1
      ? 0 + duration?.split("H")[0]
      : duration?.split("H")[0]
    : "";
  const minutes = duration?.includes("M")
    ? duration?.includes("H")
      ? duration?.split("M")[0].slice(-2).replace("H", 0)
      : duration?.split("M")[0]
    : "00";
  const seconds = duration?.slice(-3).includes("M")
    ? duration?.slice(-3).replace("M", 0)
    : duration?.slice(-3).includes("S")
    ? duration?.slice(-3).replace("S", "")
    : "00";
  const runtime =
    hours === ""
      ? `${minutes}:${seconds.replace("S", "")}`
      : `${hours}:${minutes}:${seconds.replace("S", "")}`;

  return runtime;
};

export const convertMonth = (month) => {
  const monthArr = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli ",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  return monthArr[month - 1];
};

export const getYoutubeID = (youtubeUrl) => {
  if (youtubeUrl.includes("list")) {
    if (youtubeUrl.includes(".be/")) {
      return youtubeUrl.split(".be/")[1].split("?")[0];
    } else if (youtubeUrl.includes("watch?v=")) {
      return youtubeUrl.split("watch?v=")[1].split("&")[0];
    }
  } else {
    if (youtubeUrl.includes(".be/")) {
      return youtubeUrl.split(".be/")[1];
    } else if (youtubeUrl.includes("watch?v=")) {
      return youtubeUrl.split("watch?v=")[1];
    }
  }
};
