import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";

const BasicModal = (props) => {
  const { isOpen, onClose, size, scrollBehavior, hasCloseBtn } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount
      closeOnOverlayClick={false}
      isCentered={true}
      motionPreset="slideInBottom"
      size={size ? size : "3xl"}
      scrollBehavior={scrollBehavior}
    >
      <ModalOverlay
        className="bg-gray-900 bg-opacity-75"
        backdropFilter="blur(10px)"
      />
      <ModalContent className="overflow-hidden !rounded-2xl bg-white p-5 dark:!bg-navy-800 dark:text-white dark:shadow-none">
        <ModalHeader>{props.header}</ModalHeader>
        {hasCloseBtn && <ModalCloseButton />}
        <ModalBody>{props.children}</ModalBody>

        <ModalFooter className="flex justify-end gap-2">
          {props.footer}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

BasicModal.defaultProps = {
  scrollBehavior: "outside",
  hasCloseBtn: true,
};

export default BasicModal;
