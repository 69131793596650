import { me } from "api/AuthAPI";
import { login } from "api/AuthAPI";
import InputField from "components/fields/InputField";
import UserContext from "contexts/userContext";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setFormData } from "utils";

export default function Login() {
  const navigate = useNavigate();
  const { setUserData } = React.useContext(UserContext);
  const [payload, setPayload] = useState({
    username: "",
    password: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    const body = setFormData(payload);
    const promise = login(body);

    toast
      .promise(promise, {
        pending: "Loading...",
        success: "Login Berhasil!",
        error: "Login Gagal!",
      })
      .then((res) => {
        me()
          .then((res) => {
            setUserData(res.data.data);
          })
          .catch((err) => {
            console.error(err);
            toast.error("Gagal mengambil data user!");
          });

        localStorage.setItem("authenticated", true);
        localStorage.setItem("token", res.data.data.access_token);
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="mb-28 mt-28 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Login
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Masukkan username dan password anda untuk login!
        </p>
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {/* Username */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Username*"
            placeholder="Username"
            id="username"
            type="text"
            name="username"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Username harus diisi!",
              onChange: (e) => {
                setPayload((prev) => ({
                  ...prev,
                  username: e.target.value,
                }));
              },
            }}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="********"
            id="password"
            type="password"
            name="password"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Password harus diisi!",
              onChange: (e) => {
                setPayload((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              },
            }}
          />
          <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}
