import axiosInstance from "./config";

export const showAllCompanyNews = () => {
  return axiosInstance.get("/activities");
};

export const showCompanyNews = (id) => {
  return axiosInstance.get(`/activities/${id}`);
};

export const createCompanyNews = (body) => {
  return axiosInstance.post("/activities", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCompanyNews = (id) => {
  return axiosInstance.get(`/activities/delete/${id}`);
};

export const updateCompanyNews = (id, body) => {
  return axiosInstance.post(`/activities/update/${id}`, body);
};
