import React, { useEffect, useState } from "react";
import { KeyboardSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import MyPointerSensor from "views/admin/page-builder/components/PointerSensor";
import LockedLayout from "views/admin/page-builder/locked-layout";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import { toast } from "react-toastify";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";
import _ from "lodash";

const HomeCarouselData = () => {
  const section = "carousel";
  const navigate = useNavigate();
  const pageId = 1;
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [sectionId, setSectionId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [order, setOrder] = useState(null);
  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      onActivation: (event) => {
        return;
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleRemoveItem = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            header="Hapus Carousel"
            description="Apakah anda yakin ingin menghapus carousel ini?"
            onClose={onClose}
            onConfirm={() => {
              let newData = _.cloneDeep(content.data);
              Object.entries(content.data).forEach(([key]) => {
                if (newData[key].length > 1) {
                  newData[key].splice(id, 1);
                }
                return;
              });

              if (JSON.stringify(newData) === JSON.stringify(content.data))
                newData = null;

              const finalBody = {
                page_id: pageId,
                order: order,
                name: section,
                body: newData,
              };
              finalBody.body = JSON.stringify(finalBody.body); // convert body to string
              const promise = updateHomeSection(
                sectionId,
                finalBody,
                setProgress
              );

              promise
                .then((res) => {
                  if (res.status === 200) {
                    getContent();
                  }
                })
                .catch((err) => {
                  console.error(err);
                });

              toast.promise(promise, {
                loading: "Menghapus carousel...",
                success: "Carousel berhasil dihapus",
                error: "Gagal menghapus carousel",
              });

              onClose();
            }}
          />
        );
      },
    });
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex((item) => item.name === section);
      if (~index) {
        setSectionId(res.data.data[index].id);
        setOrder(res.data.data[index].order);
        setContent({
          loading: false,
          data: res.data.data[index].body,
        });
      }
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <LockedLayout
      title={"Carousel"}
      description={
        "Drag & drop carousel di bawah untuk mengatur urutan carousel pada home page."
      }
      items={content.data}
      sensors={sensors}
      arrangeItems={() => {}}
      rightCTA={true}
      CTACopy="Tambah"
      onClick={() => navigate(`add/section/${sectionId}`)}
      updateItemUrl={`update/section/${sectionId}/item`}
      handleRemoveItem={handleRemoveItem}
    />
  );
};

export default HomeCarouselData;
