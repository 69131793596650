import axiosInstance from "./config";

export const showAllStakeholder = () => {
  return axiosInstance.get("/stakeholders");
};

export const showStakeholder = (id) => {
  return axiosInstance.get(`/stakeholders/${id}`);
};

export const showStakeholderByType = (type) => {
  return axiosInstance.get(`/stakeholders/type/${type}`);
};

export const createStakeholder = (body) => {
  return axiosInstance.post("/stakeholders", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteStakeholder = (id) => {
  return axiosInstance.get(`/stakeholders/delete/${id}`);
};

export const updateStakeholder = (id, body) => {
  return axiosInstance.post(`/stakeholders/update/${id}`, body);
};
