import { useDisclosure } from "@chakra-ui/hooks";
import {
  updateCertificate,
  createCertificate,
  deleteCertificate,
  showAllCertificate,
} from "api/CertificateAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import BasicModal from "components/modal/BasicModal";
import { DocumentPreview } from "components/react-pdf/DocumentPreview";
import TableCard from "components/table/TableCard";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { setFormData } from "utils";

const columnsData = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "IMAGE",
    accessor: "image",
  },
  {
    Header: "PDF",
    accessor: "pdf",
  },
];

const CertificateData = () => {
  const [loading, setLoading] = useState(true);

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState({});
  const [action, setAction] = useState(null);
  const [payload, setPayload] = useState({
    body: {
      title: "",
      image: undefined,
      pdf: undefined,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropImage = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        image: file,
      },
    }));
  };
  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        pdf: file,
      },
    }));
  };

  const removeImage = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        image: undefined,
      },
    });
  };
  const removeFile = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        pdf: undefined,
      },
    });
  };
  // End file upload

  const showAllData = () => {
    setLoading(true);
    showAllCertificate()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 404)
          toast.error("Data tidak dapat ditemukan!");
      });
  };

  const showModal = (action, data) => {
    setAction(action);
    if (action === "updating") {
      setTempData({
        id: data?.row.original.id,
        title: data?.row.original.title,
        image: data?.row.original.image
          ? process.env.REACT_APP_PUBLIC_URL + data?.row.original.image
          : null,
        pdf: data?.row.original.pdf
          ? process.env.REACT_APP_API_ENDPOINT +
            "/certificate/preview/" +
            data?.row.original.id
          : null,
      });
      reset({
        title: data?.row.original.title,
      });
    } else reset({ title: "" });
    onOpen();
  };

  const deleteData = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden 
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  deleteCertificate(data.row.original.id)
                    .then((res) => {
                      if (res.status === 200) {
                        showAllData();
                        toast.success("Berhasil dihapus!");
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error(err.response.data.message);
                    });
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    let body = {};

    if (payload.body.image)
      body = {
        ...data,
        image: payload.body.image,
      };
    if (payload.body.pdf)
      body = {
        ...data,
        pdf: payload.body.pdf,
      };

    body = {
      ...body,
      ...data,
    };

    const finalBody = setFormData(body);
    let promise = null;

    if (action === "adding") promise = createCertificate(finalBody);
    else if (action === "updating")
      promise = updateCertificate(tempData.id, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: `Berhasil ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      error: `Gagal ${action === "adding" ? "ditambahkan" : "di-update"}!`,
    });

    onClose();

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          setPayload({
            body: {
              title: "",
              image: undefined,
              pdf: undefined,
            },
          });
          onClose();
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      ...columns,
      {
        id: "file-image",
        Header: "File Gambar",
        Cell: (row) => {
          return row.cell.row.values.image ? (
            <img
              src={process.env.REACT_APP_PUBLIC_URL + row.cell.row.values.image}
              alt="certificate"
              className="h-full w-28 rounded-lg object-cover object-center"
            />
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      {
        id: "file-pdf",
        Header: "File PDF",
        Cell: (row) => {
          return row.cell.row.values.pdf ? (
            <span className="inline-block w-[200px]">
              <a
                href={
                  process.env.REACT_APP_API_ENDPOINT +
                  "/certificate/preview/" +
                  row.cell.row.values.id
                }
                target="_blank"
                rel="noreferrer"
                className="block truncate text-brand-500 underline hover:text-brand-600"
              >
                {process.env.REACT_APP_API_ENDPOINT +
                  "/certificate/preview/" +
                  row.cell.row.values.id}
              </a>
            </span>
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={data}
        columnsData={columnsData}
        tableTitle={"Berkas Sertifikat"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["pdf", "image", "id"]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      <BasicModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        header={action === "adding" ? "Tambah Sertifikat" : "Update Sertifikat"}
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <InputField
              extra="mb-3"
              label="Nama Sertifikat*"
              id="title"
              type="text"
              name="title"
              errors={errors}
              register={register}
              defaultValue={""}
              validationSchema={{
                required: `Nama Sertifikat tidak boleh kosong!`,
              }}
            />
            <div className="flex flex-1 flex-col gap-5">
              {action === "updating" && tempData.image && (
                <div className="flex items-center">
                  <img
                    src={tempData.image}
                    alt="certificate"
                    className="h-full w-28 rounded-lg object-cover object-center"
                  />
                  <button
                    type="button"
                    className="ml-3 text-red-500 hover:text-red-600"
                    onClick={() => {
                      setPayload({
                        ...payload,
                        body: {
                          ...payload.body,
                          image: undefined,
                        },
                      });
                    }}
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              )}
              <DropzoneFile
                text="Upload Image"
                accept={{
                  "image/jpeg": [".jpg", ".jpeg", ".png"],
                }}
                dropFile={dropImage}
                removeFile={removeImage}
                file={payload.body.image}
              />
              {action === "updating" && tempData.pdf && (
                <DocumentPreview file={tempData.pdf} />
              )}
              <DropzoneFile
                text="Upload File PDF"
                accept={{
                  "application/pdf": [".pdf"],
                }}
                dropFile={dropFile}
                removeFile={removeFile}
                file={payload.body.pdf}
              />
            </div>
          </div>
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
              type="submit"
            >
              {action === "adding" ? "Tambah" : "Update"}
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => onClose()}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default CertificateData;
