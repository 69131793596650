import React from "react";

export const ConfirmAlert = (props) => {
  const { header, description, onConfirm, onClose } = props;

  return (
    <div
      className={`fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-white bg-opacity-50 duration-300`}
    >
      <div
        className="custom-ui overflow-hidden 
      rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
      >
        <h1 className="mb-2 text-xl font-bold text-navy-700">{header}</h1>
        <p className="text-gray-700">{description}</p>
        <div className="mt-5 flex items-center justify-end gap-3">
          <button
            className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
            onClick={onConfirm}
          >
            Ya, Hapus
          </button>
          <button
            className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
            onClick={onClose}
          >
            Batal
          </button>
        </div>
      </div>
    </div>
  );
};
