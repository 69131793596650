import { pageIDs } from "variables/pageIDs";
import axiosInstance from "./config";

const pageId = pageIDs.find((page) => page.page === "home").id;

// Carousel

export const storeHomeCarousel = (body) => {
  console.log(body);
  return axiosInstance.post("/carousel", body);
};

export const showHomeCarousel = (id) => {
  return axiosInstance.get(`/carousel/${id}`);
};

export const deleteHomeCarousel = (id) => {
  return axiosInstance.get(`/carousel/delete/${id}`);
};

export const updateHomeCarousel = (id, body, updateProgress) => {
  return axiosInstance.post(`/carousel/update/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      updateProgress(percent);
    },
  });
};

// Logo Carousel

export const storeCarouselLogo = (body, updateProgress) => {
  return axiosInstance.post("/carousel-logo", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      updateProgress(percent);
    },
  });
};

export const deleteCarouselLogo = (id) => {
  return axiosInstance.get(`/carousel-logo/delete/${id}`);
};

export const showCarouselLogo = (id) => {
  return axiosInstance.get(`/carousel-logo/${id}`);
};

export const updateCarouselLogo = (id, body, updateProgress) => {
  return axiosInstance.post(`/carousel-logo/update/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      updateProgress(percent);
    },
  });
};

// Home Section
export const updateHomeSection = (id, body) => {
  return axiosInstance.post(`/home/update/${id}`, body);
};
