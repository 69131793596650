/* eslint-disable */
import React from "react";
import Widget from "components/widget/Widget";
import { articleElTypes } from "../variables/elementTypes";
import { ArrowLeft, Info } from "react-feather";
import { useNavigate } from "react-router-dom";

const ElementsList = (props) => {
  const { elements, handleClick } = props;
  const navigate = useNavigate();

  return (
    <div
      className={`sm:none duration-175 linear fixed bottom-0 top-0 left-0 !z-50 flex flex-col bg-white px-2 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0`}
    >
      <div
        className="absolute top-3 left-3 flex cursor-pointer items-center gap-2 p-1"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft width={20} />
        <span className="text-base font-semibold">Back</span>
      </div>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          Element <span className="font-medium">List</span>
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}
      <div className="ml-3 flex items-start gap-2 text-gray-700">
        <Info width={18} />
        <p>
          Klik untuk menambahkan <br />
          element pada canvas
        </p>
      </div>
      <div className="mb-auto grid grid-cols-1 gap-3 overflow-y-auto overflow-x-hidden px-3 pt-1">
        <div className="h-1" />
        {elements.map((el, index) => (
          <div
            key={index}
            className="cursor-pointer transition-all duration-300 hover:scale-105"
            onClick={() => handleClick(el)}
          >
            <Widget title={el.type} subtitle={el.name} icon={el.icon} />
          </div>
        ))}
        <div className="h-5" />
      </div>

      {/* Nav item end */}
    </div>
  );
};

ElementsList.defaultProps = {
  elements: articleElTypes,
};

export default ElementsList;
