import { useDisclosure } from "@chakra-ui/hooks";
import {
  createCommissioner,
  updateCommissioner,
  deleteCommissioner,
  showAllCommissioner,
} from "api/LeadershipAPI";
import { uploadImages } from "api/ImageAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const columnsData = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "GAMBAR",
    accessor: "body.img",
  },
  {
    Header: "NAMA",
    accessor: "body.name",
  },
  {
    Header: "POSISI (ID)",
    accessor: "body.positionId",
  },
  {
    Header: "POSISI (EN)",
    accessor: "body.positionEn",
  },
  {
    Header: "DESKRIPSI (ID)",
    accessor: "body.descriptionId",
  },
  {
    Header: "DESKRIPSI (EN)",
    accessor: "body.descriptionEn",
  },
];

const Commissioner = () => {
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState({});
  const [action, setAction] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [payload, setPayload] = useState({
    body: {
      img: undefined,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropImage = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        img: file,
      },
    }));
  };

  const removeImage = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        img: undefined,
      },
    });
  };
  // End file upload

  const showAllData = () => {
    setLoading(true);
    showAllCommissioner()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 404)
          toast.error("Data tidak dapat ditemukan!");
      });
  };

  const showModal = async (action, data) => {
    setAction(action);
    if (action === "updating") {
      setTempData({
        id: data?.row.original.id,
        name: data?.row.original.body?.name,
        positionId: data?.row.original.body?.positionId,
        positionEn: data?.row.original.body?.positionEn,
        descriptionId: data?.row.original.body?.descriptionId,
        shortDescriptionId: data?.row.original.body?.shortDescriptionId,
        descriptionEn: data?.row.original.body?.descriptionEn,
        shortDescriptionEn: data?.row.original.body?.shortDescriptionEn,
        educationId: data?.row.original.body?.educationId,
        educationEn: data?.row.original.body?.educationEn,
        img: data?.row.original.body.img
          ? process.env.REACT_APP_API_ENDPOINT +
            "/images/" +
            data?.row.original.body.img
          : null,
        imgId: data?.row.original.body.img ? data?.row.original.body.img : null,
      });
      reset({
        name: data?.row.original.body?.name,
        positionId: data?.row.original.body?.positionId,
        positionEn: data?.row.original.body?.positionEn,
        descriptionId: data?.row.original.body?.descriptionId,
        shortDescriptionId: data?.row.original.body?.shortDescriptionId,
        descriptionEn: data?.row.original.body?.descriptionEn,
        shortDescriptionEn: data?.row.original.body?.shortDescriptionEn,
        educationId: data?.row.original.body?.educationId,
        educationEn: data?.row.original.body?.educationEn,
      });
    } else reset({});
    onOpen();
  };

  const closeModal = () => {
    reset();
    setTempData({});
  };

  const deleteData = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden 
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 py-2 px-4 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  deleteCommissioner(data.row.original.id)
                    .then((res) => {
                      if (res.status === 200) {
                        showAllData();
                        toast.success("Berhasil dihapus!");
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error(err.response.data.message);
                    });
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent py-2 px-4 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setIsUploading(true);
    onOpen();

    let body = {};
    let promise = null;
    let finalBody = null;

    if (payload.body.img) {
      body = {
        images: [payload.body.img],
      };
      await uploadImages(body, setProgress)
        .then((res) => {
          delete payload.body.img;

          finalBody = {
            body: {
              ...data,
              img: res.data.data.data[0],
            },
          };
        })
        .catch((err) => {
          onClose();
          toast.error(err.response.data.data.message);
          closeModal();
        });
    } else {
      if (tempData.img)
        finalBody = {
          body: {
            ...data,
            img: tempData?.imgId,
          },
        };
      else
        finalBody = {
          body: {
            ...data,
            img: null,
          },
        };
    }

    finalBody.body = JSON.stringify(finalBody.body);

    action === "adding" && (promise = createCommissioner(finalBody));
    action === "updating" &&
      (promise = updateCommissioner(tempData.id, finalBody));

    toast.promise(promise, {
      pending: "Loading...",
      success: `Berhasil ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      error: `Gagal ${action === "adding" ? "ditambahkan" : "di-update"}!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          setPayload({
            ...{},
            body: {
              img: undefined,
            },
          });
          onClose();
          closeModal();
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsUploading(false);
        toast.error(err.response.data.message);
      });

    setIsUploading(false);
    onClose();
    closeModal();
  };

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "file-image",
        Header: "GAMBAR",
        Cell: (row) => {
          return row.cell.row.values["body.img"] ? (
            <img
              src={
                process.env.REACT_APP_API_ENDPOINT +
                "/images/" +
                row.cell.row.values["body.img"]
              }
              alt="certificate"
              className="h-full w-28 rounded-lg object-cover object-center"
            />
          ) : (
            <span className="text-gray-400">-</span>
          );
        },
      },
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={data}
        columnsData={columnsData}
        tableTitle={"Dewan Komisaris"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={[
          "body.img",
          "id",
          "body.descriptionId",
          "body.descriptionEn",
        ]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      <BasicModal
        scrollBehavior="inside"
        size="6xl"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          closeModal();
        }}
        header={
          action === "adding"
            ? "Tambah Berita (Commissioner)"
            : "Update Berita (Commissioner)"
        }
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-3 md-max:flex-col">
              <div className="flex flex-1 flex-col gap-5">
                {action === "updating" && tempData.img && (
                  <div className="flex items-center">
                    <img
                      src={tempData.img}
                      alt="certificate"
                      className="h-full w-28 rounded-lg object-cover object-center"
                    />
                    <button
                      type="button"
                      className="ml-3 text-red-500 hover:text-red-600"
                      onClick={() => {
                        setPayload({
                          ...payload,
                          body: {
                            ...payload.body,
                            img: undefined,
                          },
                        });
                        setTempData({
                          ...tempData,
                          img: undefined,
                        });
                      }}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                )}
                <DropzoneFile
                  text="Upload Image"
                  accept={{
                    "image/jpeg": [".jpg", ".jpeg", ".png"],
                  }}
                  dropFile={dropImage}
                  removeFile={removeImage}
                  file={payload.body.img}
                />
              </div>
              <div className="flex flex-1 flex-col gap-3">
                <InputField
                  extra="mb-3"
                  label="Nama*"
                  id="name"
                  type="text"
                  name="name"
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Nama tidak boleh kosong!`,
                  }}
                />
                <InputField
                  extra="mb-3"
                  label="Jabatan (ID)*"
                  id="positionId"
                  type="text"
                  name="positionId"
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Jabatan (ID) tidak boleh kosong!`,
                  }}
                />
                <InputField
                  extra="mb-3"
                  label="Jabatan (EN)*"
                  id="positionEn"
                  type="text"
                  name="positionEn"
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `Jabatan (EN) tidak boleh kosong!`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Deskripsi Singkat (ID)*"
                  placeholder="Isi dengan deskripsi singkat"
                  id="shortDescriptionId"
                  type="text"
                  name="shortDescriptionId"
                  errors={errors}
                  register={register}
                  rows={3}
                  defaultValue={""}
                  validationSchema={{
                    required: `Deskripsi Singkat (ID) tidak boleh kosong!`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Deskripsi Singkat (EN)*"
                  placeholder="Isi dengan deskripsi singkat"
                  id="shortDescriptionEn"
                  type="text"
                  name="shortDescriptionEn"
                  errors={errors}
                  register={register}
                  rows={3}
                  defaultValue={""}
                  validationSchema={{
                    required: `Deskripsi Singkat (EN) tidak boleh kosong!`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Deskripsi Personal (ID)*"
                  placeholder="Isi dengan deskripsi personal"
                  id="descriptionId"
                  type="text"
                  name="descriptionId"
                  errors={errors}
                  register={register}
                  rows={10}
                  defaultValue={""}
                  validationSchema={{
                    required: `Deskripsi Personal (ID) tidak boleh kosong!`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Deskripsi Personal (EN)*"
                  placeholder="Isi dengan deskripsi personal"
                  id="descriptionEn"
                  type="text"
                  name="descriptionEn"
                  errors={errors}
                  register={register}
                  rows={10}
                  defaultValue={""}
                  validationSchema={{
                    required: `Deskripsi Personal (EN) tidak boleh kosong!`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Pendidikan (ID)*"
                  placeholder="Isi dengan pendidikan"
                  id="educationId"
                  type="text"
                  name="educationId"
                  errors={errors}
                  register={register}
                  rows={4}
                  defaultValue={""}
                  validationSchema={{
                    required: `Pendidikan (ID) tidak boleh kosong!`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label="Pendidikan (EN)*"
                  placeholder="Isi dengan pendidikan"
                  id="educationEn"
                  type="text"
                  name="educationEn"
                  errors={errors}
                  register={register}
                  rows={4}
                  defaultValue={""}
                  validationSchema={{
                    required: `Pendidikan (EN) tidak boleh kosong!`,
                  }}
                />
              </div>
            </div>
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                type="submit"
              >
                {action === "adding" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  onClose();
                  closeModal();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default Commissioner;
