import axiosInstance from "./config";

export const createSection = (body) => {
  return axiosInstance.post("/sections", body);
};

export const updateSection = (id, body) => {
  return axiosInstance.post(`/sections/update/${id}`, body);
};

export const showSection = (id) => {
  return axiosInstance.get(`/sections/${id}`);
};

export const deleteSection = (id) => {
  return axiosInstance.get(`/sections/delete/${id}`);
};
