import React, { useContext, useEffect, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "../components/SortableItem";
import Widget from "components/widget/Widget";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { MdInfo, MdWidgets } from "react-icons/md";
import CardContainer from "components/card/CardContainer";
import ElementHandlerContext from "contexts/elementHandlerContext";
import Template from "components/widget/Template";

const LockedLayout = (props) => {
  const { deleteStatus } = useContext(ElementHandlerContext);
  const [items, setItems] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (props.items !== undefined) {
      setItems(props.items);
    }
  }, [props]);

  return (
    <CardContainer
      title={props.title}
      description={props.description}
      rightCTA={props.rightCTA}
      CTACopy={props.CTACopy}
      isCTALoading={props.isCTALoading}
      onClick={props.onClick}
      withSearchBar={props.withSearchBar}
      disabled={props.disabled}
    >
      {items !== null ? (
        items.length || Object.keys(items).length > 0 ? (
          Array.isArray(items) && items.length !== 0 ? (
            <DndContext
              sensors={props.sensors !== undefined ? props.sensors : sensors}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={Array.isArray(items) ? items : items.img}
                strategy={verticalListSortingStrategy}
              >
                <div className="flex flex-col gap-3">
                  {items.map((item, index) => {
                    return (
                      <SortableItem key={index} id={item.id}>
                        {!item?.type?.includes("template") ? (
                          <Widget
                            id={item.id}
                            // With icon == no img
                            icon={
                              item.icon === undefined &&
                              item.img === undefined &&
                              item === undefined ? (
                                <MdWidgets />
                              ) : (
                                item.icon ?? undefined
                              )
                            }
                            img={
                              item.icon === undefined && item.path !== undefined
                                ? item.path &&
                                  process.env.REACT_APP_BASE_URL + item.path
                                : item.icon === undefined && item.img
                                ? item.img &&
                                  process.env.REACT_APP_API_ENDPOINT +
                                    "/images/" +
                                    (Array.isArray(item.img)
                                      ? item.img[0]
                                      : item?.img)
                                : undefined
                            } // With img == no icon
                            title={
                              item.type?.replace("-", " ") ??
                              item?.title ??
                              (item?.title_id !== undefined
                                ? item?.title_id[0]
                                : "")
                            }
                            subtitle={
                              item?.name ??
                              item.subtitle ??
                              item?.subtitle_id[0] ??
                              ""
                            }
                            viewUrl={
                              item.baseUrl !== undefined
                                ? item.baseUrl
                                : props.viewItemUrl
                                ? typeof props.viewItemUrl === "string"
                                  ? props.viewItemUrl +
                                    "/" +
                                    (item.slug ?? item.id ?? index)
                                  : () =>
                                      props.viewItemUrl(
                                        item.slug ?? item.id ?? index
                                      )
                                : undefined
                            } // Go to view data page
                            createUrl={
                              item.hasFormPage && item.baseUrl
                                ? item.baseUrl + "/add"
                                : undefined
                            } // Go to add data page
                            // Go to edit data page
                            updateUrl={
                              item.type !== "divider" &&
                              item.type !== "dividers" &&
                              props.updateItemUrl
                                ? typeof props.updateItemUrl === "string"
                                  ? props.updateItemUrl +
                                    "/" +
                                    (item.id ?? index)
                                  : () =>
                                      props.updateItemUrl(
                                        "updating", // action type
                                        item.id ?? index
                                      )
                                : undefined
                            }
                            // Remove data
                            removeData={
                              props.handleRemoveItem !== undefined
                                ? () => handleRemoveItem(item.id ?? index)
                                : undefined
                            }
                          />
                        ) : (
                          <Template
                            id={item.id}
                            type={item?.default_type ?? item.type}
                            // With icon == no img
                            icon={
                              item.icon === undefined &&
                              item.img === undefined &&
                              item === undefined ? (
                                <MdWidgets />
                              ) : (
                                item.icon ?? undefined
                              )
                            }
                            img={
                              item.icon === undefined && item.path !== undefined
                                ? item.path &&
                                  process.env.REACT_APP_BASE_URL + item.path
                                : item.icon === undefined && item.img
                                ? item.img &&
                                  process.env.REACT_APP_API_ENDPOINT +
                                    "/images/" +
                                    (Array.isArray(item.img)
                                      ? item.img[0]
                                      : item?.img)
                                : undefined
                            } // With img == no icon
                            title={
                              item.type?.replace("-", " ") ??
                              item?.title ??
                              (item?.title_id !== undefined
                                ? item?.title_id[0]
                                : "")
                            }
                            subtitle={
                              item?.name ??
                              item.subtitle ??
                              item?.subtitle_id[0] ??
                              ""
                            }
                            viewUrl={
                              item.baseUrl !== undefined
                                ? item.baseUrl
                                : props.viewItemUrl
                                ? typeof props.viewItemUrl === "string"
                                  ? props.viewItemUrl +
                                    "/" +
                                    (item.slug ?? item.id ?? index)
                                  : () =>
                                      props.viewItemUrl(
                                        item.slug ?? item.id ?? index
                                      )
                                : undefined
                            } // Go to view data page
                            createUrl={
                              item.hasFormPage && item.baseUrl
                                ? item.baseUrl + "/add"
                                : undefined
                            } // Go to add data page
                            // Go to edit data page
                            updateUrl={
                              item.type !== "divider" &&
                              item.type !== "dividers" &&
                              props.updateItemUrl
                                ? typeof props.updateItemUrl === "string"
                                  ? props.updateItemUrl +
                                    "/" +
                                    (item.id ?? index)
                                  : () =>
                                      props.updateItemUrl(
                                        "updating", // action type
                                        item.id ?? index
                                      )
                                : undefined
                            }
                            // Remove data
                            removeData={
                              props.handleRemoveItem !== undefined
                                ? () => handleRemoveItem(item.id ?? index)
                                : undefined
                            }
                          />
                        )}
                      </SortableItem>
                    );
                  })}
                </div>
              </SortableContext>
            </DndContext>
          ) : typeof items === "object" && items !== null ? (
            <DndContext
              sensors={props.sensors !== undefined ? props.sensors : sensors}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={Array.isArray(items) ? items : items.img}
                strategy={verticalListSortingStrategy}
              >
                <div className="flex flex-col gap-3">
                  {(items.img === undefined ? items : items.img).map(
                    (item, index) => {
                      return (
                        <SortableItem key={index} id={item.id}>
                          {!item?.type?.includes("template") ? (
                            <Widget
                              id={index}
                              // With icon == no img
                              icon={
                                item.icon === undefined &&
                                item.img === undefined &&
                                item === undefined ? (
                                  <MdWidgets />
                                ) : (
                                  item.icon ?? undefined
                                )
                              }
                              img={
                                item
                                  ? process.env.REACT_APP_API_ENDPOINT +
                                    "/images/" +
                                    item
                                  : undefined
                              } // With img == no icon
                              title={
                                item.type?.replace("-", " ") ??
                                (items.title_id && items.title_id[index]) ??
                                ""
                              }
                              subtitle={
                                item?.name ??
                                (items.subtitle_id &&
                                  items.subtitle_id[index]) ??
                                ""
                              }
                              viewUrl={item.baseUrl ?? undefined} // Go to view data page
                              createUrl={
                                item.hasFormPage && item.baseUrl
                                  ? item.baseUrl + "/add"
                                  : undefined
                              } // Go to add data page
                              // Go to edit data page
                              updateUrl={
                                item.type !== "divider" &&
                                item.type !== "dividers" &&
                                props.updateItemUrl
                                  ? props.updateItemUrl +
                                    "/" +
                                    (item.id ?? index)
                                  : undefined
                              }
                              // Remove data
                              removeData={
                                props.handleRemoveItem !== undefined
                                  ? () => handleRemoveItem(item.id ?? index)
                                  : undefined
                              }
                            />
                          ) : (
                            <Template
                              id={index}
                              type={item?.default_type ?? item.type}
                              // With icon == no img
                              icon={
                                item.icon === undefined &&
                                item.img === undefined &&
                                item === undefined ? (
                                  <MdWidgets />
                                ) : (
                                  item.icon ?? undefined
                                )
                              }
                              img={
                                item
                                  ? process.env.REACT_APP_API_ENDPOINT +
                                    "/images/" +
                                    item
                                  : undefined
                              } // With img == no icon
                              title={
                                item.type?.replace("-", " ") ??
                                (items.title_id && items.title_id[index]) ??
                                ""
                              }
                              subtitle={
                                item?.name ??
                                (items.subtitle_id &&
                                  items.subtitle_id[index]) ??
                                ""
                              }
                              viewUrl={item.baseUrl ?? undefined} // Go to view data page
                              createUrl={
                                item.hasFormPage && item.baseUrl
                                  ? item.baseUrl + "/add"
                                  : undefined
                              } // Go to add data page
                              // Go to edit data page
                              updateUrl={
                                item.type !== "divider" &&
                                item.type !== "dividers" &&
                                props.updateItemUrl
                                  ? props.updateItemUrl +
                                    "/" +
                                    (item.id ?? index)
                                  : undefined
                              }
                              // Remove data
                              removeData={
                                props.handleRemoveItem !== undefined
                                  ? () => handleRemoveItem(item.id ?? index)
                                  : undefined
                              }
                            />
                          )}
                        </SortableItem>
                      );
                    }
                  )}
                </div>
              </SortableContext>
            </DndContext>
          ) : (
            <div className="my-3 flex h-full flex-col items-center justify-center">
              <MdInfo className="text-4xl text-gray-400" />
              <p className="text-gray-400">Tidak ada data</p>
            </div>
          )
        ) : (
          <div className="my-3 flex h-full flex-col items-center justify-center">
            <MdInfo className="text-4xl text-gray-400" />
            <p className="text-gray-400">Tidak ada data</p>
          </div>
        )
      ) : (
        <div className="my-3 flex h-full flex-col items-center justify-center">
          <MdInfo className="text-4xl text-gray-400" />
          <p className="text-gray-400">Tidak ada data</p>
        </div>
      )}
    </CardContainer>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const res = arrayMove(items, oldIndex, newIndex);
        props.arrangeItems(res);
        return res;
      });
    }
  }

  function handleRemoveItem(id) {
    props.handleRemoveItem(id);

    if (deleteStatus) {
      const newItems = items.filter((item) => item.id !== id);
      setItems(newItems);
      props.arrangeItems(newItems);
    }
  }
};

LockedLayout.defaultProps = {
  title: "Page Builder",
  description: "Page Builder Example",
};

export default LockedLayout;
