import axiosInstance from "./config";

export const getAllPageContent = () => {
  return axiosInstance.get("/pages");
};

export const getHomeContent = () => {
  return axiosInstance.get(`/home`);
};

export const getPageContent = (id) => {
  return axiosInstance.get(`/pages/${id}`);
};

export const toggleCareerPage = () => {
  return axiosInstance.get(`/pages/career/status`);
};
