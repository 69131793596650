import React from "react";

// Libs
import InputColor from "react-input-color";
import { useDisclosure } from "@chakra-ui/hooks";
import { TableActionButton } from "components/buttons/TableActionButton";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";

// APIs
import { getAllPageContent, getPageContent } from "api/PageAPI";
import { updateChart } from "api/ChartAPI";

// Components & Views
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import InputField from "components/fields/InputField";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import { showChart } from "api/ChartAPI";

const columnsData = [
  {
    Header: "COLOR",
    accessor: "color",
  },
  {
    Header: "LABEL",
    accessor: "label",
  },
  {
    Header: "VALUES",
    accessor: "data",
  },
];

const Charts = () => {
  const { slug, default_id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [targetEl, setTargetEl] = React.useState([]);
  const [originalData, setOriginalData] = React.useState({});
  const [color, setColor] = React.useState({
    hex: "#5e72e4",
  });

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [targetId, setTargetId] = React.useState(null);
  const [confirm, setConfirm] = React.useState({
    show: false,
    data: null,
  });
  const [action, setAction] = React.useState(null);
  const [sectionProps, setSectionProps] = React.useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Refactor object data to array data
  const refactorData = (data) => {
    return data?.data?.map((value, index) => ({
      label: data.label[index],
      data: value,
      color: data.color[index],
    }));
  };

  const getTargetPage = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = await getAllPageContent();
    return allPageData.data.data.find(
      (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
    );
  };

  const showAllData = async () => {
    setLoading(true);
    const targetPage = await getTargetPage();
    if (default_id) {
      showChart(default_id)
        .then((res) => {
          setSectionProps(res?.data?.data);
          setOriginalData(res?.data?.data?.body);
          setTargetEl(refactorData(res?.data?.data?.body));
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    } else {
      getPageContent(targetPage.id)
        .then((res) => {
          const targetEl = res.data.data.contents.filter((item) => {
            return item.name === "charts" && item.id === parseInt(default_id);
          });

          if (targetEl) {
            setSectionProps(targetEl[0]);
            setOriginalData(targetEl[0]?.body);
            setTargetEl(refactorData(targetEl[0]?.body));
          }
          setLoading(false);
          reset({});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          if (err.status === 404) toast.error("Data tidak dapat ditemukan!");
        });
    }
  };

  const showModal = (action, data) => {
    setAction(action);
    setTargetId(data?.row.index);

    if (action === "adding") reset({});
    if (action === "updating") {
      reset({
        label: data.row.original.label,
        value: data.row.original.data,
      });
      setColor({
        hex: data.row.original.color,
      });
    }

    onOpen();
  };

  const deleteData = (data) => {
    setAction("deleting");
    setTargetId(data?.row.index);
    setConfirm({
      show: true,
      data: data.row.index,
    });
  };

  const onSubmit = async (data) => {
    let finalBody = {
      id: sectionProps.id,
      name: sectionProps.name,
      order: sectionProps.order,
      page_id: sectionProps.page_id,
      body: {
        ...originalData,
      },
    };

    if (action === "adding") {
      const label = originalData?.data
        ? [...originalData.label, data.label]
        : [data.label];
      const dataValues = originalData?.data
        ? [...originalData.data, data.value]
        : [data.value];
      const colorValues = originalData?.data
        ? [...originalData.color, color.hex]
        : [color.hex];

      finalBody.body = {
        label,
        data: dataValues,
        color: colorValues,
      };
    } else if (action === "updating") {
      finalBody.body = {
        label: originalData.label.map((item, index) =>
          index === targetId ? data.label : item
        ),
        data: originalData.data.map((item, index) =>
          index === targetId ? data.value : item
        ),
        color: originalData.color.map((item, index) =>
          index === targetId ? color.hex : item
        ),
      };
    } else if (action === "deleting") {
      finalBody.body = {
        label: originalData.label.filter((_, index) => index !== targetId),
        data: originalData.data.filter((_, index) => index !== targetId),
        color: originalData.color.filter((_, index) => index !== targetId),
      };
    }

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateChart(sectionProps.id, finalBody);

    toast.promise(promise, {
      pending: "Menambahkan data...",
      success: `Data Chart berhasil di${
        action === "deleting" ? "hapus" : "update"
      }!`,
      error: `Data Chart gagal di${
        action === "deleting" ? "hapus" : "update"
      }!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Terjadi kesalahan!");
      });

    setAction(null);
    onClose();
  };
  // End Modal

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "formatted_color",
        Header: "COLOR",
        type: "free",
        Cell: (data) => {
          return (
            <div
              className="h-8 w-8 rounded-full"
              style={{
                backgroundColor: data.row.original.color,
              }}
            />
          );
        },
      },
      {
        id: "formatted_values",
        Header: "PERCENTAGE",
        type: "free",
        Cell: (data) => {
          return (
            <div className="flex flex-col">
              <span className="text-sm font-semibold">
                {data.row.original.label}
              </span>
              <span className="text-xs">
                {data.row.original.data} ({data.row.original.data}%)
              </span>
            </div>
          );
        },
      },
      ...columns,
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  React.useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={targetEl?.length > 0 ? targetEl : []}
        columnsData={columnsData}
        tableTitle={`${(slug || location.pathname.split("/")[2])
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")} Chart`}
        CTACopy="Data"
        isCTALoading={loading}
        disabled={loading}
        addData={() => showModal("adding")}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={["color", "data", "label"]}
        loading={loading}
      />
      {confirm.show &&
        createPortal(
          <ConfirmAlert
            header="Hapus Data"
            description="Apakah anda yakin ingin menghapus data ini?"
            onClose={() => {
              setConfirm({
                show: false,
                data: null,
              });
              setAction(null);
            }}
            onConfirm={() => {
              onSubmit(confirm.data);
              setConfirm({
                show: false,
                data: null,
              });
            }}
          />,
          document.body
        )}
      <BasicModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          reset({});
          setColor({
            hex: "#5e72e4",
          });
          onClose();
        }}
        header={action === "adding" ? "Tambah Data" : "Update Data"}
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <label className="ml-1 text-sm font-medium text-navy-700 dark:text-white">
              Warna/Color<span className="text-red-500">*</span>
            </label>
            <InputColor
              initialValue={color.hex}
              onChange={setColor}
              placement="top-center"
              className="!h-14 !w-full"
            />
            <InputField
              extra="mb-3 w-full"
              label={`Label*`}
              id={`label`}
              type="text"
              name={`label`}
              errors={errors}
              register={register}
              defaultValue={""}
              validationSchema={{
                required: `Label harus diisi!`,
              }}
            />
            <InputField
              extra={`mb-3 w-full`}
              label="Percentage*"
              placeholder="1-100"
              id="value"
              type="number"
              step="0.01"
              name="value"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Presentase harus diisi!",
                pattern: {
                  value: /^(?:[1-9]\d*|0)?(?:\.\d+)?$/,
                  message: "Presentase harus diantara 1-100",
                },
              }}
            />
          </div>
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
              type="submit"
            >
              {action === "adding" ? "Tambah" : "Update"}
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                reset({});
                setColor({
                  hex: "#5e72e4",
                });
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default Charts;
