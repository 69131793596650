import DropzoneFile from "components/dropzone/DropzoneFile";
import React from "react";

const SingleImage = (props) => {
  const { existingImage, dropFile, removeFile, file } = props;

  return (
    <div className="flex flex-col">
      {existingImage && (
        <div className="relative m-3 my-3 flex w-full max-w-xs items-center justify-center rounded-xl border-2 border-gray-200">
          <img
            src={
              existingImage === undefined
                ? "https://via.placeholder.com/120x100?text=No+Image"
                : process.env.REACT_APP_API_ENDPOINT +
                  "/images/" +
                  existingImage
            }
            alt={
              existingImage !== undefined &&
              process.env.REACT_APP_API_ENDPOINT + "/images/" + existingImage
            }
            className="rounded-lg"
          />
        </div>
      )}
      <div className="flex w-full items-center gap-3">
        <div className="flex flex-1 flex-col gap-3">
          <DropzoneFile
            text="Upload file"
            accept={{
              "image/jpeg": [".jpg", ".jpeg", ".png"],
              "image/svg+xml": [".svg"],
            }}
            dropFile={dropFile}
            removeFile={removeFile}
            file={file}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleImage;
