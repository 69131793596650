import React, { useContext, useEffect, useState } from "react";
import { KeyboardSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import MyPointerSensor from "views/admin/page-builder/components/PointerSensor";
import LockedLayout from "views/admin/page-builder/locked-layout";
import InputField from "components/fields/InputField";
import BasicModal from "components/modal/BasicModal";
import { useDisclosure } from "@chakra-ui/hooks";
import { useForm } from "react-hook-form";
import { showAllProduct } from "api/ProductAPI";
import { toast } from "react-toastify";
import DropzoneFile from "components/dropzone/DropzoneFile";
import { uploadImages } from "api/ImageAPI";
import { createProduct } from "api/ProductAPI";
import { showProduct } from "api/ProductAPI";
import { ConfirmAlert } from "components/modal/ConfirmAlert";
import { deleteProduct } from "api/ProductAPI";
import { updateProduct } from "api/ProductAPI";
import { createPortal } from "react-dom";
import ElementHandlerContext from "contexts/elementHandlerContext";
import Skeleton from "react-loading-skeleton";

const AllProduct = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleDeleteStatus } = useContext(ElementHandlerContext);
  const [items, setItems] = useState();
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [id, setId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState("adding");
  const [payload, setPayload] = useState({
    body: {
      image: undefined,
      image_dark: undefined,
    },
  });
  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      onActivation: (event) => {
        return;
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const arrangeItems = (items) => {
    setItems(items);
  };

  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        image: file,
      },
    }));
  };

  const dropFile2 = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        image_dark: file,
      },
    }));
  };

  const removeFile = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        image: undefined,
      },
    });
  };

  const removeFile2 = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        image_dark: undefined,
      },
    });
  };

  const showModal = (action, item) => {
    setAction(action);
    if (action === "updating") {
      showProduct(item).then((res) => {
        reset({
          name: res.data.data.name,
        });
        setId(res.data.data.id);
        setPayload({
          ...payload,
          body: {
            ...payload.body,
            ...res.data.data,
          },
        });
      });
      onOpen();
      return;
    }
    setPayload({
      body: {
        image: undefined,
        image_dark: undefined,
      },
    });
    reset({
      name: "",
    });
    onOpen();
  };

  const handleRemoveItem = (id) => {
    setShowConfirm(true);
    setId(id);
  };

  const onSubmit = async (data) => {
    onOpen();

    let body = {};
    let finalBody = null;
    let promise = null;

    if (action === "adding") {
      if (!payload.body.image || !payload.body.image_dark) {
        toast.error("Foto Produk harus diisi!");
        return;
      } else {
        setIsUploading(true);
        body = {
          images: [payload.body.image, payload.body.image_dark],
        };
        await uploadImages(body, setProgress)
          .then((res) => {
            delete payload.body.image;
            delete payload.body.image_dark;
            finalBody = {
              ...data,
              img: res.data.data.data[0],
              img_dark: res.data.data.data[1],
            };

            action === "adding" && (promise = createProduct(finalBody));
          })
          .catch((err) => {
            console.error(err);
            toast.error("Gagal mengunggah foto produk!");
          });
      }
    } else {
      if (payload.body.image && payload.body.image_dark) {
        body = {
          images: [payload.body.image, payload.body.image_dark],
        };
        setIsUploading(true);

        await uploadImages(body, setProgress).then((res) => {
          delete payload.body.image;
          delete payload.body.image_dark;
          finalBody = {
            ...data,
            img: res.data.data.data[0],
            img_dark: res.data.data.data[1],
          };

          action === "updating" && (promise = updateProduct(id, finalBody));
        });
      } else if (payload.body.image) {
        body = {
          images: [payload.body.image],
        };
        setIsUploading(true);

        await uploadImages(body, setProgress).then((res) => {
          delete payload.body.image;
          finalBody = {
            ...data,
            img: res.data.data.data[0],
            img_dark: payload.body.img_dark,
          };

          action === "updating" && (promise = updateProduct(id, finalBody));
        });
      } else if (payload.body.image_dark) {
        body = {
          images: [payload.body.image_dark],
        };
        setIsUploading(true);

        await uploadImages(body, setProgress).then((res) => {
          delete payload.body.image_dark;
          finalBody = {
            ...data,
            img: payload.body.img,
            img_dark: res.data.data.data[0],
          };

          action === "updating" && (promise = updateProduct(id, finalBody));
        });
      } else {
        finalBody = {
          ...data,
          img: payload.body.img,
          img_dark: payload.body.img_dark,
        };

        action === "updating" && (promise = updateProduct(id, finalBody));
      }
    }

    if (promise !== null) {
      toast.promise(promise, {
        pending: "Loading...",
        success: `Berhasil ${
          action === "adding" ? "ditambahkan" : "di-update"
        }!`,
        error: `Gagal ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      });

      promise
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            getAllProduct();
            setPayload({
              body: {
                image: undefined,
              },
            });
            onClose();
            setIsUploading(false);
            reset();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getAllProduct = () => {
    showAllProduct()
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal memuat data produk. Silahkan coba lagi!");
      });
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
      <LockedLayout
        title={"All Product"}
        description={"Atur semua produk yang ada pada website"}
        items={items}
        sensors={sensors}
        arrangeItems={arrangeItems}
        rightCTA={true}
        CTACopy={"Tambah Produk"}
        onClick={() => showModal("adding")}
        handleRemoveItem={handleRemoveItem}
        viewItemUrl={"layout"}
        updateItemUrl={showModal}
      />
      {showConfirm &&
        createPortal(
          <ConfirmAlert
            header="Hapus Produk"
            description="Apakah anda yakin ingin menghapus produk ini?"
            onClose={() => {
              setShowConfirm(false);
              setId(0);
            }}
            onConfirm={() => {
              let promise = deleteProduct(id);
              promise
                .then((res) => {
                  if (res.status === 200) {
                    getAllProduct();
                    handleDeleteStatus(true);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });

              toast.promise(promise, {
                loading: "Menghapus Produk...",
                success: "Produk berhasil dihapus",
                error: "Gagal menghapus Produk",
              });

              setShowConfirm(false);
              setId(0);
            }}
          />,
          document.body
        )}
      <BasicModal
        scrollBehavior="inside"
        size="4xl"
        isOpen={isOpen}
        onClose={() => {
          setPayload({
            body: {
              image: undefined,
            },
          });
          onClose();
        }}
        header="Tambah Produk"
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
              <InputField
                extra="mb-3"
                label="Nama Produk*"
                id="name"
                type="text"
                name="name"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Nama Produk tidak boleh kosong!`,
                }}
              />
              <div className="flex gap-2">
                <div className="flex flex-1 flex-col">
                  {action === "updating" &&
                    (!payload.body.img ? (
                      <Skeleton
                        height={200}
                        className="my-2 w-full rounded-xl"
                      />
                    ) : (
                      <img
                        src={
                          payload.body.img &&
                          process.env.REACT_APP_API_ENDPOINT +
                            "/images/" +
                            payload.body.img
                        }
                        alt=""
                        className="h-full w-full rounded-lg object-cover object-center"
                      />
                    ))}
                  <div className="flex flex-col gap-3">
                    <p className="mx-2 text-navy-700">
                      Image Light<span className="text-red-500">*</span>
                    </p>
                    <DropzoneFile
                      text="Upload file"
                      accept={{
                        "image/jpeg": [".jpg", ".jpeg", ".png"],
                        "image/svg+xml": [".svg"],
                      }}
                      dropFile={dropFile}
                      removeFile={removeFile}
                      file={payload.body.image}
                    />
                  </div>
                </div>
                <div className="flex flex-1 flex-col">
                  {action === "updating" &&
                    (!payload.body.img_dark ? (
                      <Skeleton
                        height={200}
                        className="my-2 w-full rounded-xl"
                      />
                    ) : (
                      <img
                        src={
                          process.env.REACT_APP_API_ENDPOINT +
                          "/images/" +
                          payload.body.img_dark
                        }
                        alt=""
                        className="h-full w-full rounded-lg object-cover object-center"
                      />
                    ))}
                  <div className="flex flex-col gap-3">
                    <p className="mx-2 text-navy-700">
                      Image Dark<span className="text-red-500">*</span>
                    </p>
                    <DropzoneFile
                      text="Upload file"
                      accept={{
                        "image/jpeg": [".jpg", ".jpeg", ".png"],
                        "image/svg+xml": [".svg"],
                      }}
                      dropFile={dropFile2}
                      removeFile={removeFile2}
                      file={payload.body.image_dark}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                type="submit"
              >
                {action === "adding" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  setPayload({
                    body: {
                      image: undefined,
                    },
                  });
                  onClose();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default AllProduct;
