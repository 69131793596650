import axiosInstance from "./config";

export const createTextBox = (body) => {
  return axiosInstance.post("/text-boxes", body);
};

export const updateTextBox = (id, body) => {
  return axiosInstance.post(`/text-boxes/update/${id}`, body);
};

export const deleteTextBox = (id) => {
  return axiosInstance.get(`/text-boxes/delete/${id}`);
};
