export const pageIDs = [
  { id: 1, page: "home" },
  { id: 2, page: "milestone" },
  { id: 3, page: "visi-misi" },
  { id: 4, page: "export" },
  { id: 5, page: "domestic" },
  { id: 6, page: "business-commitee" },
  { id: 7, page: "internal-audit-corporate-secretary" },
  { id: 8, page: "risk-management" },
  { id: 9, page: "whistleblowing-system" },
  { id: 10, page: "boards" },
  { id: 11, page: "main-supplier" },
  { id: 12, page: "customers" },
  { id: 13, page: "affiliate" },
  { id: 14, page: "corporate-activity" },
  { id: 15, page: "corporate-social-responsibility" },
  { id: 16, page: "how-it-works" },
  { id: 17, page: "certificate" },
];
