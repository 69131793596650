import React from "react";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import FormLayout from "views/admin/forms";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoData } from "api/VideoAPI";
import { convertDuration } from "utils";
import { Trash } from "react-feather";
import { getYoutubeID } from "utils";
import { getVideoPreviewData } from "utils/API";
import { updateHomeSection } from "api/HomeAPI";
import { getHomeContent } from "api/PageAPI";
import { toast } from "react-toastify";

const HomeYoutubeForm = () => {
  const section = "social_media";
  const { id } = useParams();
  const navigate = useNavigate();
  const [sectionProps, setSectionProps] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(id ? true : false);
  const [itemId, setItemId] = React.useState(id ? id : null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Youtube Video Handler
  const [videos, setVideos] = React.useState([]);
  const [preview, setPreview] = React.useState([]);
  const [thumbnails, setThumbnails] = React.useState([]);
  const [videoURLs, setVideoURLs] = React.useState([]);

  const handleInputVideo = async (e) => {
    let youtubeUrl = e.target.value;
    let youtubeId = getYoutubeID(youtubeUrl);

    if (youtubeId !== "") {
      const newPreview = await getVideoPreviewData(youtubeId);
      const { runtime, youtube_video_id } = newPreview;

      if (videos.length === 0) {
        setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
        setPreview([...preview, newPreview]);
        setVideos([...videos, runtime, youtube_video_id]);
      } else {
        // Check if video already exist in the state
        if (
          videos?.find((video) => video.youtube_video_id === youtubeId) ===
          undefined
        ) {
          setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
          setPreview([...preview, newPreview]);
          setVideos([...videos, runtime, youtube_video_id]);
        }
      }
      e.target.value = "";
    }
  };

  const handleDeleteVideo = (index) => {
    const newPreview = preview.filter((_preview, i) => i !== index);
    const newVideos = videos.filter((_video, i) => i !== index);
    const newThumbnails = thumbnails.filter((_thumbnail, i) => i !== index);
    setPreview(newPreview);
    setVideos(newVideos);
    setThumbnails(newThumbnails);
    reset({
      youtube: null,
    });
  };
  // End Youtube Video Handler

  const onSubmit = () => {
    const finalBody = {
      name: sectionProps.data.name,
      order: sectionProps.data.order,
      body: {
        ...sectionProps.data.body,
        youtube: videoURLs,
      },
    };

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateHomeSection(sectionProps.data.id, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil menambahkan video!",
      error: "Gagal menambahkan video! Silakan coba lagi.",
    });

    promise
      .then((res) => {
        navigate(`/home-page/youtube`, { replace: true });
        if (res.status === 200) {
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionProps({
        loading: false,
        data: res.data.data[index],
      });
    });
  };

  React.useEffect(() => {
    getContent();
  }, []);

  return (
    <FormLayout
      title="Tambah Video"
      description="Form untuk menambahkan video Youtube"
      children={
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-3 rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <div className="flex flex-col items-start gap-3">
              <div className="flex w-full flex-1 flex-col gap-3">
                {preview?.length > 0 ? (
                  preview?.map((preview, index) => {
                    return (
                      <div key={index}>
                        <div className="mb-4 grid grid-cols-6">
                          <div className="col-span-2 mb-3 mr-3">
                            <img
                              src={
                                thumbnails?.length === 0
                                  ? `https://via.placeholder.com/640x360.png?text=No+Thumbnail`
                                  : thumbnails[index]
                              }
                              alt={thumbnails[index]}
                              className="h-full w-full rounded-lg object-cover"
                            />
                          </div>
                          <div className="col-span-4 flex flex-col justify-between">
                            <div>
                              <h3 className="mb-1 w-full text-lg font-bold">
                                {preview?.title}
                              </h3>
                              <p
                                className="w-full overflow-hidden text-gray-700"
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {preview?.subtitle}
                              </p>
                            </div>
                            <div className="flex w-full items-end justify-between">
                              <div>
                                <p className="mb-0 text-sm font-semibold text-gray-700">
                                  Durasi
                                </p>
                                <p className="font-semibold">
                                  {preview?.runtime}
                                </p>
                              </div>
                              <div
                                className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-red-100 py-2 px-3 text-sm font-medium text-red-600 transition duration-200 hover:text-red-700 dark:bg-red-500 dark:text-red-400 dark:hover:text-red-300 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                              >
                                <button
                                  className="absolute top-0 left-0 h-full w-full sm-max:w-full"
                                  onClick={() => handleDeleteVideo(index)}
                                />
                                <Trash width={16} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="my-3 text-center font-semibold text-gray-600">
                    Belum ada video yang ditambahkan. Silahkan tambahkan video
                  </p>
                )}
              </div>
              <div className="w-full flex-1">
                <InputField
                  extra="mb-3"
                  label="Link Youtube*"
                  placeholder="https://www.youtube.com/watch?v=..."
                  id="link"
                  type="text"
                  name="link"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    onChange: (e) => {
                      setVideoURLs([...videoURLs, e.target.value]);
                      handleInputVideo(e);
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="linear max-w-max rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save Data
              </button>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default HomeYoutubeForm;
